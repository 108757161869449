import React, {useState, useEffect} from "react";
import './assets/css/style.css';
import Logo from './assets/images/logo.png';
import Background from './assets/images/bg-02.png';
import APIs from "./components/APIs";
import 'bootstrap/dist/css/bootstrap.css'; 
import './assets/css/login.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Admin = () => {
const [authToken, setAuthToken] = useState('');
const [userName, setUserName] = useState('');
const [password, setPassword] = useState('');
const [isRequiredInput, setIsRequiredInput] = useState(false);
const [isInvalid, setIsInvalid] = useState(false);
const [isVerify, setIsVerify] = useState()

const user = 'Sysnet';
const pass = '4332wurx'

useEffect(() => {
    // Retrieve the value from localStorage
    const storedData = localStorage.getItem('authToken');
    if (storedData === null) {
        setIsVerify(false);
    }else{
        setIsVerify(true);
    }
  }, []);

const HandleSubmit = async (e) => {
    e.preventDefault();
    if(userName === '' || password === '') {
        setIsRequiredInput(true);
        setIsInvalid(false)
    }
    else if (userName === user && password === pass) {
        setIsInvalid(false)
        await fetch(process.env.REACT_APP_API_ADDRESS + "/auth/v1/token", { 
            method: 'GET', 
            headers: { 
              "Content-Type": "application/json" // Set Type to JSON
            },
        })
        .then(response => response.json()) 
        .then((value)=>{
            localStorage.setItem('authToken', value.token)
            setIsVerify(true);
           
        })
        
        .catch(error => console.log('error', error));
    }else{
        setIsRequiredInput(false);
        setIsInvalid(true)
    }   
    
}
const [showPassword, setShowPassword] = React.useState(false);

const handleClickShowPassword = () => setShowPassword((show) => !show);

const handleMouseDownPassword = (event) => {
event.preventDefault();
}
    return (
        <div>
            <img src={Background} id="home_page_background" />
            {!isVerify ? 
                <div className="login-block border">
                    <Card variant="outlined" className="card p-4">
                        <FormControl fullWidth sx={{ m: 1 }} variant="standard" className="p-4">
                            <h1>ADMINISTRATION</h1>
                            <Stack sx={{ width: '100%' }} spacing={2}>
                                {isRequiredInput && userName === '' || isRequiredInput && password === '' ? 
                                    <Alert severity="warning">Username and Password are required</Alert>
                                
                                : isInvalid ? 
                                <Alert severity="error">Invalid Username and Password</Alert> 
                                : ''
                                }
                            </Stack>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }} className="mb-4">
                                <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField 
                                    error={isRequiredInput && userName === '' ? true : false}
                                    label="Enter your username" 
                                    variant="standard" 
                                    onChange={(e) => setUserName(e.target.value)} 
                                    className="w-100"
                                />
                            </Box>
                        
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }} className="mb-4">
                                <LockIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField
                                    error={isRequiredInput && password === '' ? true : false}
                                    label="Enter your passowrd" 
                                    variant="standard" 
                                    onChange={(e) => setPassword(e.target.value)} 
                                    className="w-100"
                                    type ='password'
                                />
                            </Box>
                            <center>
                                <Button variant="contained" onClick={HandleSubmit} className="w-50">
                                    Submit
                                </Button>
                            </center>
                        </FormControl>
                    </Card>
                </div>
            :
                <APIs 
                    Logo = {Logo}
                />
            }
        </div>
    )
}
export default Admin;