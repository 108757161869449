import TokenCheck from "./TokenCheck";

const GetCPODetails = async (cookies, setCookie) => {
    let res = await TokenCheck(cookies, setCookie);
    if (res.status !== undefined && res.status === "PASSED") {
        console.log("[ACCESS TOKEN]: ", res.access_token)
    } else {
        // clearAllCookies();
    }

    const myHeaders = new Headers();
    myHeaders.append(
        "Authorization",
        "Bearer " + res.access_token
    );

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };


    let fetchData;

    try {
        let response = await fetch(
            `${process.env.REACT_APP_SERVICE_URL}/ocpi/cpo/2.2/details`,
            requestOptions
        );

        if (!response.ok) {
            // Handle specific error status codes
            if (response.status === 403) {
                console.log("Unauthorized: Token invalid.");
                console.log(response)
                // localStorage.clear();
                // window.location.reload()
            } else {
                console.log(`Server error: ${response.status}`);
            }
        }
        fetchData = response.json();
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }

    return fetchData;
}

export default GetCPODetails;