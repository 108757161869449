import React, {useState} from "react";

const Navbar = (props) => {

    return (
        <div className="container-fluid">
            <div className="p-3 mb-5">
                <img src={props.Logo} className="img-fluid"></img>
            </div>
            <div>
                <button onClick={props.btnHome} className={props.page === 'Home' ? 'btn bg-white w-100 text-left' : 'btn w-100 text-left text-white border border-white'}>
                    <i className="bi bi-house"></i>
                    &nbsp;
                    HOME
                </button>
            
                <button onClick={props.btnCPO} className={props.page === 'CPO' || props.page === 'Update' ? 'btn bg-white w-100 text-left' : 'btn w-100 text-left text-white border border-white'} >
                    <i className="bi bi-clipboard-data"></i>
                    &nbsp;
                    CPO List
                </button>
            </div>
        </div>
    )
}
export default Navbar;