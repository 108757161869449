import React, { useState } from "react"
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
const FormTwo = (props) => {
const facilitiesListOneStart = 1;
const facilitiesListOneEnd = 9;
const facilitiesListTwoStart = 10;
const facilitiesListTwoEnd = 17;
const facilitiesListOne = Array.from({ length: facilitiesListOneEnd - facilitiesListOneStart + 1 }, (_, index) => facilitiesListOneStart + index);
const facilitiesListTwo = Array.from({ length: facilitiesListTwoEnd - facilitiesListTwoStart + 1 }, (_, index) => facilitiesListTwoStart + index);


    return(
      <div className="p-4">
        <form className='p-4'>
        <div className='row'>
          <div className='col-lg-6'>
            <FormControl sx={{ minWidth: '100%' }}>
              <InputLabel 
                  id="demo-simple-select-helper-label"
                  error={props.isErrorPageTwo && props.floor_level === '' ? true : false}
                  >Floor Level</InputLabel>
              <Select
                  error={props.isErrorPageTwo && props.floor_level === '' ? true : false}
                  className='mb-3'
                  label="floor_level"
                  type="text"
                  name='floor_level'
                  value={props.floor_level} 
                  onChange={props.handleInputChange}
              >
                  <MenuItem value='LOWER GROUND FLOOR'>LOWER GROUND FLOOR</MenuItem>
                  <MenuItem value='UPPER GROUND FLOOR'>UPPER GROUND FLOOR</MenuItem>
                  <MenuItem value='SECOND FLOOR'>SECOND FLOOR</MenuItem>
                  <MenuItem value='THIRD FLOOR'>THIRD FLOOR</MenuItem>
                  <MenuItem value='FOUTH FLOOR'>FOUTH FLOOR</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className='col-lg-6'>
            <FormControl sx={{ minWidth: '100%' }}>
              <InputLabel 
                  id="demo-simple-select-helper-label"
                  error={props.isErrorPageTwo && props.parking_restriction === '' ? true : false}
                  >Parking Restriction</InputLabel>
              <Select
                  error={props.isErrorPageTwo && props.parking_restriction === '' ? true : false}
                  className='mb-3'
                  label="parking_restriction"
                  type="text"
                  name='parking_restriction'
                  value={props.parking_restriction} 
                  onChange={props.handleInputChange}
              >
                  {props.parkingRestrictionsList.map((types) => (
                <MenuItem value={types.parking_restriction}>
                    {types.parking_restriction}
                </MenuItem>
            ))} 
              </Select>
            </FormControl>
          </div>
          <div className='col-lg-6 mb-2'>
            <small className="form-text text-muted">
                <h3>Facilities</h3>
                <i className='d-block'>Kindly check the available facilities around the EV Charging Location Site</i>
            </small>
          <label className="text-danger">{props.isErrorPageTwo && props.facilities.length === 0 ? <b className="d-block">Please check at least one option to proceed</b> : ''}</label>
            <div className="row mb-2">
              <div className="col-lg">
                {facilitiesListOne.map((item) => (
                  <div>
                    <input value={props.facilitiesList[item].facility} type="checkbox" name='updatedList' id={props.facilitiesList[item].facility} onChange={props.handleCheckFacilities} checked={props.isCheckedFacilities(props.facilitiesList[item].facility)} />
                    <small htmlFor={props.facilitiesList[item].facility}><b style={{fontSize:'12px'}}>&nbsp;{props.facilitiesList[item].facility}</b></small>
                  </div>
                  ))
                }
              </div>
              <div className="col-lg">
                {facilitiesListTwo.map((item) => (
                  <div>
                    <input value={props.facilitiesList[item].facility} type="checkbox" name='updatedList' id={props.facilitiesList[item].facility} onChange={props.handleCheckFacilities} checked={props.isCheckedFacilities(props.facilitiesList[item].facility)} />
                    <small htmlFor={props.facilitiesList[item].facility}><b style={{fontSize:'12px'}}>&nbsp;{props.facilitiesList[item].facility}</b></small>
                  </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
          <small className="form-text text-muted">
            <h3>Charging Capabilities</h3>
            <i className='d-block'>Kindly check the available facilities around the EV Charging Location Site</i>
          </small>
          <label className="text-danger">{props.isErrorPageTwo && props.chargingCapabilities.length === 0 ? <b>Please check at least one option to proceed</b> : ''}</label>
            {props.chargingCapabilitiesList.map((item) => (
              <div>
                  <input 
                    value={item.charging_capability.toLocaleUpperCase()} 
                    type="checkbox" 
                    name='updatedList' 
                    id={item.charging_capability.toLocaleUpperCase()} 
                    onChange={props.handleCheckChargingCapabilities} 
                    checked={props.isCheckedChargingCapabilities(item.charging_capability.toLocaleUpperCase())} />
                  <small htmlFor={item.charging_capability.toLocaleUpperCase()} ><b style={{fontSize:'12px'}}>&nbsp;{item.charging_capability.toLocaleUpperCase()}</b></small>
              </div>
              ))
            }
          </div>
        </div>
        </form>
      </div>
    )
}
export default FormTwo;