import React, { useState, useEffect } from "react";
import Navbar from "./Includes/Navbar";
import Swal from "sweetalert2";
import CPORegisteredList from "./Includes/CPORegisteredList";
const APIs = (props) => {
const [cpoList, setcpoList] = useState ();
const [cpoId, setcpoId] = useState ();
useEffect(() => {
    registered_companies();
}, []);

const registered_companies = async () => {
    await fetch(process.env.REACT_APP_API_ADDRESS + "/registered_companies", {
        method: 'GET',
        
    })
    .then(response => response.json())
    .then(result => {
        setcpoList(result.data)
       
    })
    .catch(error => console.log('error', error));
};

const btn_Get_CPO_Id = (value, index) => {
    setPage('Update')
    setcpoId(value.id);
};

const [page, setPage] = useState('Home')
const btnHome = () => {
    setPage('Home')
}
const btnCPO = () => {
    setPage('CPO')
}
//------------------------------------------------------- UPDATE LOGO STATUS APPROVED
const update_logo_status_approved = async (e) => {
    e.preventDefault();
    await fetch(process.env.REACT_APP_API_ADDRESS + "/admin/update_logo_status", {
        method: 'PATCH', 
        body: JSON.stringify({
            "company_id": cpoId,
            "status": "APPROVED"
        }),
        headers: {
            'Content-Type': 'application/json', 
        }
    })
    .then(response => response.json())
    .then(result => {
        registered_companies();
        Swal.fire({
            title: 'APPROVED',
            text: 'Updated successfully',
            icon: "success"
        });
    })
    .catch(error => console.log('error', error));
};
//------------------------------------------------------- UPDATE LOGO STATUS REJECTED
const update_logo_status_rejected = async (e) => {
    e.preventDefault();
    await fetch(process.env.REACT_APP_API_ADDRESS + "/admin/update_logo_status", {
        method: 'PATCH', 
        body: JSON.stringify({
            "company_id": cpoId,
            "status": "REJECTED"
        }),
        headers: {
            'Content-Type': 'application/json', 
        }
    })
    .then(response => response.json())
    .then(result => {
        registered_companies();
        Swal.fire({
            title: 'REJECTED',
            text: 'Updated successfully',
            icon: "error"
        });
    })
    .catch(error => console.log('error', error));
};

//------------------------------------------------------- UPDATE WEBSITE STATUS APPROVED
const update_website_status_approved = async (e) => {
    e.preventDefault();
    await fetch(process.env.REACT_APP_API_ADDRESS + "/admin/update_website_status", {
        method: 'PATCH', 
        body: JSON.stringify({
            "company_id": cpoId,
            "status": "APPROVED"
        }),
        headers: {
            'Content-Type': 'application/json', 
        }
    })
    .then(response => response.json())
    .then(result => {
        registered_companies();
        Swal.fire({
            title: 'APPROVED',
            text: 'Updated successfully',
            icon: "success"
        });
    })
    .catch(error => console.log('error', error));
};
//------------------------------------------------------- UPDATE WEBSITE STATUS REJECTED
const update_website_status_rejected = async (e) => {
    e.preventDefault();
    await fetch(process.env.REACT_APP_API_ADDRESS + "/admin/update_website_status", {
        method: 'PATCH', 
        body: JSON.stringify({
            "company_id": cpoId,
            "status": "REJECTED"
        }),
        headers: {
            'Content-Type': 'application/json', 
        }
    })
    .then(response => response.json())
    .then(result => {
        registered_companies();
        Swal.fire({
            title: 'REJECTED',
            text: 'Updated successfully',
            icon: "error"
        });
    })
    .catch(error => console.log('error', error));
};

//------------------------------------------------------- UPDATE TERMS AND CONDITIONS STATUS APPROVED
const update_tac_status_approved = async (e) => {
    e.preventDefault();
    await fetch(process.env.REACT_APP_API_ADDRESS + "/admin/update_tac_status", {
        method: 'PATCH', 
        body: JSON.stringify({
            "company_id": cpoId,
            "status": "APPROVED"
        }),
        headers: {
            'Content-Type': 'application/json', 
        }
    })
    .then(response => response.json())
    .then(result => {
        registered_companies();
        Swal.fire({
            title: 'APPROVED',
            text: 'Updated successfully',
            icon: "success"
        });
    })
    .catch(error => console.log('error', error));
};
//------------------------------------------------------- UPDATE TERMS AND CONDITIONS STATUS REJECTED
const update_tac_status_rejected = async (e) => {
    e.preventDefault();
    await fetch(process.env.REACT_APP_API_ADDRESS + "/admin/update_tac_status", {
        method: 'PATCH', 
        body: JSON.stringify({
            "company_id": cpoId,
            "status": "REJECTED"
        }),
        headers: {
            'Content-Type': 'application/json', 
        }
    })
    .then(response => response.json())
    .then(result => {
        registered_companies();
        Swal.fire({
            title: 'REJECTED',
            text: 'Updated successfully',
            icon: "error"
        });
    })
    .catch(error => console.log('error', error));
};

    return (
    <>
        <div className="row">
            <div id='home_sidebar' className="col-lg-2">
                <Navbar 
                    Logo = {props.Logo}
                    page = {page}
                    btnHome = {btnHome}
                    btnCPO = {btnCPO}
                />
            </div>
            <div className="col-lg">
                <CPORegisteredList 
                    Logo = {props.Logo}
                    Background = {props.Background}
                    page = {page}
                    btnHome = {btnHome}
                    btnCPO = {btnCPO}
                    cpoList = {cpoList}
                    cpoId = {cpoId}
                    btn_Get_CPO_Id = {btn_Get_CPO_Id}
                    update_logo_status_approved = {update_logo_status_approved}
                    update_logo_status_rejected = {update_logo_status_rejected}
                    update_website_status_approved = {update_website_status_approved}
                    update_website_status_rejected = {update_website_status_rejected}
                    update_tac_status_approved = {update_tac_status_approved}
                    update_tac_status_rejected = {update_tac_status_rejected}
                />
            </div>
        </div>  
    </>)
};

export default APIs;