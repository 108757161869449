import React, {useState, useEffect} from "react";
import TextField from '@mui/material/TextField';

import { useNavigate  } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Swal from "sweetalert2";

const ForgotPassword = (props) => {
const navigate = useNavigate();
const [emailAddress, setEmailAddress] = useState('')
const [msg, setMsg] = useState('')
const [cookies, setCookie] = useCookies('');
const email_pattern = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;

//======================================================================================================== 4.2 Function to handle email submission for password reset)
const EmailSumbit = async (e) => {
    e.preventDefault() // Prevent the default form submission behavior)
        if(emailAddress === '') {
            setMsg('error1'); // Set a message indicating error1 (e.g., email address is empty))
            Swal.fire({
                position: "center",
                icon: "warning",
                html: `
                    An email address is required for the reset password
                `,
                showConfirmButton: false,
                timer: 1500
            });
        }else if(!email_pattern.test(emailAddress)){
            Swal.fire({
                position: "center",
                icon: "warning",
                html: `
                    Email address has an invalid format.
                `,
                showConfirmButton: false,
                timer: 1500
            });
            setMsg('error2'); // Set a message indicating error2 (e.g., invalid email format))
        }else{
            //============================================================================================ 4.2.1 Make a POST request to send a password reset link to the specified email address)
            Swal.fire({
                position: "center",
                html: `
                    <p>Checking email address for Verifacation link</p><br/>
                   
                    <div class="spinner-grow text-primary" role="status">
                    <span class="sr-only"></span>
                    </div>
                    <div class="spinner-grow text-secondary" role="status">
                        <span class="sr-only"></span>
                    </div>
                    <div class="spinner-grow text-success" role="status">
                        <span class="sr-only"></span>
                    </div>
                    <div class="spinner-grow text-danger" role="status">
                        <span class="sr-only"></span>
                    </div>
                    <div class="spinner-grow text-warning" role="status">
                        <span class="sr-only"></span>
                    </div>
                    <div class="spinner-grow text-info" role="status">
                        <span class="sr-only"></span>
                    </div>
                    <div class="spinner-grow text-light" role="status">
                        <span class="sr-only"></span>
                    </div>
                    <div class="spinner-grow text-dark" role="status">
                        <span class="sr-only"></span>
                    </div>
                    
                `,
                showConfirmButton: false,
                timer: 3000
            });
            await fetch(process.env.REACT_APP_API_ADDRESS + "/nsp/v1/forgot-password-link", {
                method: 'POST',
                body: JSON.stringify({ 
                    "email": emailAddress 
                }),
                headers: {
                    'pragma': 'no-cache',
                    'cache-control': 'no-cache',
                    "Authorization": cookies.token_type +" "+  cookies.forgot_password_token, // Include the forgot password token in the headers
                    "Content-Type": "application/json" 
                },
            })
            .then(response => response.json()) //========================================================= 4.2.2 Parse the JSON response
            .then((value)=>{
                //======================================================================================== 4.2.3 Handle the response based on the status
                if(value.status === 200) {
                    //==================================================================================== 4.2.4 Password reset link sent successfully
                    Swal.fire({
                        title:"A Verifacation link has been sent to your email account",
                        text: "Please click on the link that just been sent to your email account to continue the reset password",
                        icon: "success"
                    });
                }else{
                    //==================================================================================== 4.2.5 Email does not exist
                    Swal.fire({
                        title: "We're sorry!",
                        text: "This email does not exist!",
                        icon: "error"
                    });
                }
            })
            .catch(error => console.log('error', error)); //============================================== 4.2.6 Handle errors (e.g., network issues) by logging to the console)
            setMsg(''); //================================================================================ Reset the message state)
        }
}
    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <form className="login100-form">
                        <span className="login100-form-title p-b-43">
                            <img src={props.Logo} className='img-fluid'/>
                        </span>
                        <h5 className="text-center mb-2"><b>Reset Your Password</b></h5>
                        <>
                        {/* STEP 1 : ENTER EMAIL ADDRESS */}
                            <p>Please enter your email address. You will receive a link to create a new password via email</p>
                            <TextField 
                                error={msg === 'error1' || msg === 'error2' ? true : false}
                                label="Email Address"
                                className="form-control mb-3"
                                value={emailAddress}
                                onChange={((e) => setEmailAddress(e.target.value))}
                                helperText = {msg === 'error1' ? 'Please fill out this field' : msg === 'error2' ? 'Invalid Email Address' : ''}
                            />
                            <div className="flex-sb-m w-full p-t-3 p-b-32 d-flex justify-content-between">
                                <button className="btn text-danger" onClick={props.toLogin}>
                                    <i className="bi bi-arrow-left-circle-fill" />&nbsp;Back
                                </button> 
                                <button className="btn btn-primary" onClick={EmailSumbit}>
                                    Submit &nbsp;<i className="bi bi-arrow-right-circle-fill" />
                                </button> 
                            </div>
                        </>
                      
                    </form>
                    {/* <img src={props.LoginImage} className='img-fluid login100-more'/> */}
                </div>
            </div>
        </div>
    )
}
export default ForgotPassword;