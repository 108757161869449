import TokenCheck from "./TokenCheck";

const UploadLocationList = async (cookies, setCookie, CSVFile, FileName) => {
    let res = await TokenCheck(cookies, setCookie);
    if (res.status !== undefined && res.status === "PASSED") {
        console.log("[ACCESS TOKEN]: ", res.access_token)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + res.access_token);

        const formdata = new FormData();
        formdata.append("file", CSVFile, FileName);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        let fetchData;

        try {
            let response = await fetch(
                `${process.env.REACT_APP_SERVICE_URL}/ocpi/cpo/api/v2/locations/uploads/temp/csv`,
                requestOptions
            );

            if (!response.ok) {
                // Handle specific error status codes
                if (response.status === 403) {
                    console.log("Unauthorized: Token invalid.");
                    console.log(response)
                    // localStorage.clear();
                    // window.location.reload()
                } else {
                    console.log(`Server error: ${response.status}`);
                }
            }
            fetchData = response.json();
        } catch (error) {
            console.log("Network error or other unexpected issue:", error);
        }

        return fetchData;
    } else {
        // clearAllCookies();
    }


}

export default UploadLocationList;