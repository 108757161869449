import RefreshToken from "./RefreshToken";

const TokenCheck = async (userData, setCookie) => {
    let timeExpiry = new Date(userData.access_token_expiresIn);
    let timeCurrent = new Date(Date.now());
    console.log("[TIME EXPIRY]:", timeExpiry, "[CURRENT TIME]:", timeCurrent)
    if (timeCurrent > timeExpiry) {
        console.log("IS_EXPIRED")
        let refreshToken = userData.refresh_token;
        let res = await RefreshToken(refreshToken);
        console.log(res)
        if (res.status === 200) {
            setCookie('auth_token', res.data.access_token, { path: '/' });
            setCookie('access_token_expiresIn', res.data.access_expires_in * 1000, { path: '/' });
            setCookie('refresh_token', res.data.refresh_token, { path: '/' });
            setCookie('refresh_token_expiresIn', res.data.refresh_expires_in, { path: '/' });
            setCookie('type', res.data.role, { path: '/' });
            console.log("[(NEW)TIME EXPIRY]:", timeExpiry, "[(NEW)CURRENT TIME]:", timeCurrent)
            return {
                status: "PASSED",
                access_token: res.data.access_token
            };
        } else {
            return {
                status: "FAILED",
            };
        }
    } else {
        console.log("NOT_EXPIRED")
        return {
            status: "PASSED",
            access_token: userData.auth_token
        };
    }
}

export default TokenCheck;