import TokenCheck from "./TokenCheck";


const GetLocationList = async (cookies, setCookie, limit, offset) => {
    let res = await TokenCheck(cookies, setCookie);
    if (res.status !== undefined && res.status === "PASSED") {
        console.log("[ACCESS TOKEN]: ", res.access_token)

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + res.access_token);

        const graphql = JSON.stringify({
            query: `query Locations {\r\n    locations(limit: ${limit}, offset: ${offset}) {\r\n        id\r\n        cpo_owner_id\r\n        name\r\n        address\r\n        distance\r\n        address_lat\r\n        address_lng\r\n        city\r\n        province\r\n        region\r\n        postal_code\r\n        country_code\r\n        publish\r\n        date_created\r\n        date_modified\r\n        evses {\r\n            uid\r\n        }\r\n        facilities {\r\n            id\r\n            code\r\n            description\r\n        }\r\n        parking_restrictions {\r\n            id\r\n            code\r\n            description\r\n        }\r\n        parking_types {\r\n            id\r\n            code\r\n            description\r\n        }\r\n        images {\r\n            id\r\n            location_id\r\n            url\r\n        }\r\n    }\r\n}\r\n`,
            variables: {}
        })

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: graphql,
            redirect: "follow"
        };

        let fetchData;

        try {
            let response = await fetch(
                `${process.env.REACT_APP_SERVICE_URL}/ocpi/cpo/graphql`,
                requestOptions
            );

            if (!response.ok) {
                // Handle specific error status codes
                if (response.status === 403) {
                    console.log("Unauthorized: Token invalid.");
                    console.log(response)
                    // localStorage.clear();
                    // window.location.reload()
                } else {
                    console.log(`Server error: ${response.status}`);
                }
            }
            fetchData = response.json();
        } catch (error) {
            console.log("Network error or other unexpected issue:", error);
        }

        return fetchData;
    } else {
        // clearAllCookies();
    }

}

export default GetLocationList;