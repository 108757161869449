import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Login from "./Components/Login";
import Register from "./Components/Register";
import ForgotPassword from "./Components/ForgotPassword";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "../Assets/css/Login.css";
import Logo from "../Assets/images/logo.png";

import TokenCheck from "../../Functions/TokenCheck";

const LoginPage = (props) => {
	const navigate = useNavigate();
	const [cookies, setCookie] = useCookies("");

	// ========================================================================== 1.1.2. Function to fetch a new authentication token provided by the developer
	const [authToken, setAuthToken] = useState(null);
	const [isPage, setIsPage] = useState("Login");
	const [isClickRegister, setIsClickRegister] = useState(false);
	const [tokenRegister, setTokenRegister] = useState("");
	const [tokenType, setTokenType] = useState("");

	//============================================================================================= 2 CPO Registration
	const toRegister = async (e) => {
		//========================================================== 2.1 Function to Access Token for CPO Registration
		e.preventDefault();
		setIsPage("Register");
		if (!isClickRegister) {
			await fetch(process.env.REACT_APP_API_ADDRESS + "/authmodule/v1/token", {
				//============= 2.1.1 Make a POST request to the token endpoint
				method: "POST",
				body: JSON.stringify({
					type: "client_credentials",
				}),
				headers: {
					pragma: "no-cache",
					"cache-control": "no-cache",
					Authorization: "Bearer " + authToken,
					"Content-Type": "application/json",
				},
			})
				.then((response) => response.json()) //================================================ 2.1.2 Parse the JSON response
				.then((value) => {
					setTokenRegister(value.access_token); //======================================== 2.1.3 Set the Token for Registration
					setTokenType(value.token_type); //============================================== 2.1.4 Set the Token Type for Registration
					setIsClickRegister(true);
				})
				.catch((error) => console.log("error", error));
		}
	};

	// =========================================================================================== 4.1 Function to Access Token for Forgot Password
	const [isClickForgotPassword, setIsClickForgotPassword] = useState(false);
	const toForgotPassword = async (e) => {
		e.preventDefault(); // Prevent the default form submission behavior
		setIsPage("ForgotPassword"); // Set the page to 'ForgotPassword'
		if (!isClickForgotPassword) {
			// Check if the Forgot Password button is not clicked
			await fetch(process.env.REACT_APP_API_ADDRESS + "/authmodule/v1/token", {
				//============ 4.1.1 Make a POST request to obtain a token for forgot password
				method: "POST",
				body: JSON.stringify({
					type: "client_credentials",
				}),
				headers: {
					pragma: "no-cache",
					"cache-control": "no-cache",
					Authorization: "Bearer " + authToken, // Include the authentication token in the headers
					"Content-Type": "application/json",
				},
			})
				.then((response) => response.json()) //=============================================== 4.1.2 Parse the JSON response
				.then((value) => {
					//================================================================= 4.1.3 Set cookies related to forgot password
					setCookie("forgot_password_token", value.access_token, { path: "/" });
					setCookie("access_token_expiresIn", value.expires_in, { path: "/" });
					setCookie("token_type", value.token_type, { path: "/" });
					setIsClickForgotPassword(true); //Mark forgot password as clicked
				})
				.catch((error) => console.log("error", error)); //==================================== 4.1.4 Handle errors (e.g., network issues) by logging to the console
		}
	};

	const toLogin = () => {
		setIsPage("Login");
	};

	return (
		<>
			{isPage === "Login" ? (
				<Login
					isPage={isPage}
					toRegister={toRegister}
					toForgotPassword={toForgotPassword}
					authToken={authToken}
					Logo={Logo}
					onSetIfLoggedIn={props.onSetIfLoggedIn}
				/>
			) : isPage === "Register" ? (
				<Register
					tokenRegister={tokenRegister} //2.1.3.a. Passes various props (Token for Registration) to the Register component.
					tokenType={tokenType} // 2.1.4.a.  Passes various props (Token Type for Registration) to the Register component.
					toLogin={toLogin}
					Logo={Logo}
				/>
			) : (
				<ForgotPassword
					isClickForgotPassword={isClickForgotPassword}
					toLogin={toLogin}
					Logo={Logo}
				/>
			)}
		</>
	);
};
export const CPOLogo = { Logo };
export default LoginPage;
