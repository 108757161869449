import TokenCheck from "./TokenCheck";
import UploadLogo from "./UploadLogo";

const UpdateLogo = async (cookies, setCookie, Logo) => {
    if (Logo !== null) {
        let request = UploadLogo(Logo)
        let result = await request.then(async (res) => {
            if (res.status === 200) {
                let fetchData;
                let req = await TokenCheck(cookies, setCookie);
                console.log(req)
                if (req.status !== undefined && req.status === "PASSED") {
                    console.log("[ACCESS TOKEN]: ", req.access_token)

                    const myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append("Authorization", "Bearer " + req.access_token);

                    const raw = JSON.stringify({
                        "logo": Logo.name
                    });

                    const requestOptions = {
                        method: "PATCH",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow"
                    };



                    try {
                        let response = await fetch(
                            `${process.env.REACT_APP_SERVICE_URL}/ocpi/cpo/2.2/cpo/logo/upload`,
                            requestOptions
                        );

                        if (!response.ok) {
                            // Handle specific error status codes
                            if (response.status === 403) {
                                console.log("Unauthorized: Token invalid.");
                                console.log(response)
                                // localStorage.clear();
                                // window.location.reload()
                            } else {
                                console.log(`Server error: ${response.status}`);
                            }
                        }

                        fetchData = response.json();
                    } catch (error) {
                        console.log("Network error or other unexpected issue:", error);
                    }

                } else {
                    // clearAllCookies();
                }
                if (fetchData !== undefined) {
                    console.log(fetchData)
                    return await fetchData;
                }

            }

        })
        return result;
    }
}

export default UpdateLogo;