import React from "react";

const Footer = (props) => {
	const YearNow = new Date();

	return (
		<footer style={{ backgroundColor: "#0C0354" }}>
			<div className='text-center p-2'>
				<p className='text-white'>
					<b style={{ fontSize: "30px" }}>Find EV Plug</b>
					<br />
					<b style={{ fontSize: "14px" }}>Centralized EV Location and status</b>
				</p>
				<p
					className='text-white'
					style={{ fontSize: "10px" }}>
					<b>Copyright © {YearNow.getFullYear()}. All Rights Reserved</b>
				</p>
			</div>
		</footer>
	);
};
export default Footer;
