import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { bgcolor, borderRadius, display, padding, styled, width } from "@mui/system";

import { useCookies } from "react-cookie";

import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CollectionsIcon from "@mui/icons-material/Collections";
import EvStationIcon from "@mui/icons-material/EvStation";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import GetLocationList from "../../../Functions/GetLocationList";
import { cardHeaderClasses } from "@mui/material";
import { type } from "@testing-library/user-event/dist/type";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	"& .MuiDataGrid-row": {
		"& .MuiDataGrid-cell": {
			whiteSpace: "normal",
			lineHeight: "1.5",
			display: "flex",
			alignItems: "center",
			position: "sticky",
		},
		"& .MuiDataGrid-cell:nth-of-type(2)": {
			left: 0,
			zIndex: 2,
			bgcolor: "white",
			padding: "0 8px 0 8px",
			backgroundColor: "white",
		},

		"& .MuiDataGrid-cell:nth-of-type(3)": {
			left: "3%",
			zIndex: 2,
			backgroundColor: "white",
			padding: "0 8px 0 8px",
			boxShadow: "2px 0 5px -2px rgba(0,0,0,0.3)",
		},
		"& .MuiDataGrid-cell:nth-of-type(4), & .MuiDataGrid-cell:nth-of-type(7), & .MuiDataGrid-cell:nth-of-type(8)": {
			justifyContent: "center",
		},
	},
	"& .MuiDataGrid-columnHeaders": {
		lineHeight: "1.5",
	},
	"& .MuiDataGrid-columnHeader:nth-of-type(2)": {
		position: "sticky",
		left: 0,
		zIndex: 2,
		backgroundColor: "white",
	},
	"& .MuiDataGrid-columnHeader:nth-of-type(3)": {
		position: "sticky",
		left: "3%",
		zIndex: 2,
		backgroundColor: "white",
		borderRight: "1px solid rgba(224, 224, 224, 1)",
		boxShadow: "2px 0 5px -2px rgba(0,0,0,0.3)",
	},
	"& .MuiDataGrid-columnHeader:nth-of-type(3).sticky": {
		borderRight: "1px solid rgba(224, 224, 224, 1)",
		boxShadow: "2px 0 5px -2px rgba(0,0,0,0.3)",
	},
}));

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "60%",
	bgcolor: "background.paper",
	boxShadow: 24,
	// p: 4,
};

function createData(
	id,
	location_name,
	address,
	coordinates,
	image_list,
	status,
	evse_count,
	facilities,
	parking_types
) {
	return { id, location_name, address, coordinates, image_list, status, evse_count, facilities, parking_types };
}

function convertTimestampToDate(timestamp) {
	// Convert the timestamp to a date object
	const date = new Date(timestamp);

	// Format the date as a string (YYYY-MM-DD)
	const year = date.getUTCFullYear();
	const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
	const day = String(date.getUTCDate()).padStart(2, "0");

	return `${year}-${month}-${day}`;
}

const LocationTable = (props) => {
	const [cookies, setCookie, removeCookie] = useCookies();
	const [LocationList, setLocationList] = useState([]);
	const [Limit, setLimit] = useState(10);
	const [OffSet, setOffSet] = useState(0);
	const [LocationImageModal, setLocationImageModal] = useState(false);
	const [SelectedLocation, setSelectedLocation] = useState();

	const isEmptyObject = (obj) => {
		return Object.keys(obj).length === 0 && obj.constructor === Object;
	};

	const handleLocationImageModalOpen = (location) => {
		setSelectedLocation(location);
		setLocationImageModal(true);
	};

	const handleLocationImageModalClose = () => setLocationImageModal(false);

	// const [OrderBy, setOrderBy] = useState("location");
	// const [Order, setOrder] = useState("DESC");

	const AddressCell = ({ params }) => {
		// useEffect(() => {
		// 	console.log(params);
		// }, []);

		const handleChange = (event) => {
			const { value } = event.target;
			params.api.setEditCellValue({ id: params.id, field: params.field, value });
		};

		return (
			<div>
				<Accordion sx={{ boxShadow: "none" }}>
					<AccordionSummary
						expandIcon={<ArrowDropDownIcon />}
						aria-controls='panel2-content'
						id='panel2-header'>
						<Typography>{params.value.address}</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
						<Typography variant='caption'>
							<Typography
								variant='caption'
								sx={{ fontWeight: "bold" }}>
								Country Code:
							</Typography>
							&nbsp;
							{params.value.country_code}
						</Typography>
						<Typography variant='caption'>
							<Typography
								variant='caption'
								sx={{ fontWeight: "bold" }}>
								Region:
							</Typography>
							&nbsp;
							{params.value.region}
						</Typography>
						<Typography variant='caption'>
							<Typography
								variant='caption'
								sx={{ fontWeight: "bold" }}>
								Province:
							</Typography>
							&nbsp;
							{params.value.province}
						</Typography>
						<Typography variant='caption'>
							<Typography
								variant='caption'
								sx={{ fontWeight: "bold" }}>
								City:
							</Typography>
							&nbsp;
							{params.value.city}
						</Typography>
						<Typography variant='caption'>
							<Typography
								variant='caption'
								sx={{ fontWeight: "bold" }}>
								Postal Code:
							</Typography>
							&nbsp;
							{params.value.postal_code !== null ? params.value.postal_code : "N/A"}
						</Typography>
					</AccordionDetails>
				</Accordion>
			</div>
		);
	};

	const CoordinatesCell = ({ params }) => {
		const connectorCount = params.value.length;

		// useEffect(() => {
		// 	console.log(params);
		// }, []);

		const handleChange = (event) => {
			const { value } = event.target;
			params.api.setEditCellValue({ id: params.id, field: params.field, value });
		};

		return (
			<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%" }}>
				<Box sx={{ display: "flex" }}>
					<Typography
						variant='subtitle2'
						fontWeight={"bold"}>
						Latitude:&nbsp;
					</Typography>
					<Typography variant='subtitle2'>{params.value.lat}</Typography>
				</Box>
				<Box sx={{ display: "flex" }}>
					<Typography
						variant='subtitle2'
						fontWeight={"bold"}>
						Longitude:&nbsp;
					</Typography>
					<Typography variant='subtitle2'>{params.value.lng}</Typography>
				</Box>
			</Box>
		);
	};

	const ImageListCell = ({ params }) => {
		const LocationImageDetails = {
			id: params.id,
			image_list: params.value,
		};

		useEffect(() => {
			console.log(params);
		}, []);

		return (
			<Box>
				<Button
					disabled
					onClick={() => handleLocationImageModalOpen(LocationImageDetails)}
					startIcon={<CollectionsIcon />}
					variant='contained'
					size='small'
					color='warning'
					sx={{ textTransform: "none" }}>
					View/Modify Image/s ({params.value.length}/5)
				</Button>
			</Box>
		);
	};

	const EVSEListCell = ({ params }) => {
		useEffect(() => {
			console.log(params);
		}, []);

		return (
			<>
				<Box>
					<Button
						onClick={handleLocationImageModalOpen}
						disabled
						startIcon={<EvStationIcon />}
						variant='contained'
						size='small'
						color='primary'
						sx={{ textTransform: "none" }}>
						Show EVSE/s ({params.value})
					</Button>
				</Box>
			</>
		);
	};

	const ListToolTipCell = ({ params }) => {
		// useEffect(() => {
		// 	console.log(params);
		// }, []);

		const handleChange = (event) => {
			const { value } = event.target;
			params.api.setEditCellValue({ id: params.id, field: params.field, value });
		};

		return (
			<Box sx={{ display: "flex", gap: 1, width: "100%" }}>
				{params.value.slice(0, 2).map((capability, index) => {
					return (
						<Chip
							sx={{
								borderRadius: params.field === "parking_types" ? "8px" : "unset",
								backgroundColor: params.field === "parking_types" && "gainsboro",
								fontWeight: params.field === "parking_types" && "bold",
							}}
							variant={params.field === "parking_types" ? "contained" : "outlined"}
							label={capability.description}
						/>
					);
				})}

				{params.value.length > 2 && (
					<Tooltip
						sx={{ backgroundColor: "red" }}
						componentsProps={{
							tooltip: {
								sx: {
									bgcolor: "common.white",
									"& .MuiTooltip-arrow": {
										color: "common.black",
									},
									boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)",
								},
							},
						}}
						arrow
						title={
							<Box
								sx={{
									display: "flex",
									gap: 1,
									backgroundColor: "white",
									maxWidth: "300px",
									flexWrap: "wrap",
									padding: "4px",
								}}>
								{params.value.map((capability, index) => {
									return (
										<Chip
											sx={{ borderRadius: "unset" }}
											variant='outlined'
											label={capability.description}
										/>
									);
								})}
							</Box>
						}
						placement='bottom'>
						<Chip
							sx={{ borderRadius: "unset", cursor: "pointer" }}
							variant='outlined'
							label={"+" + (params.value.length - 2)}
						/>
					</Tooltip>
				)}
			</Box>
		);
	};

	const ImageBox = (imageData) => {
		const containerStyle = {
			position: "relative",
			minWidth: "33.33%", // This sets the width to 33.33% of the parent
			paddingBottom: "34.77%", // This maintains the aspect ratio (calculated as 480/460 * 100%)
			border: "1px solid gainsboro",
			boxSizing: "border-box",
			borderRadius: "16px",
		};

		const contentStyle = {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
		};

		useEffect(() => {
			console.log(imageData);
		}, []);

		return (
			<Box sx={containerStyle}>
				<Box sx={contentStyle}>
					{!isEmptyObject(imageData) ? (
						<Box
							sx={{
								height: "100%",
								width: "100%",
								backgroundImage: `url(${imageData.imageURL})`,
								backgroundSize: "cover", // Ensures the image covers the box while maintaining aspect ratio
								backgroundPosition: "center", // Centers the image
								backgroundRepeat: "no-repeat", // Prevents the image from repeating
								borderRadius: "16px",
								position: "relative",
							}}>
							<IconButton
								color='inherit'
								sx={{
									backgroundColor: "white",
									right: 0,
									position: "absolute",
									margin: "8px",
									"&:hover": {
										backgroundColor: "gainsboro",
									},
								}}>
								<CloseIcon sx={{ color: "red", fontSize: "1rem!important" }} />
							</IconButton>
						</Box>
					) : (
						<Button
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								height: "100%",
								width: "100%",
							}}
							color='inherit'>
							<AddPhotoAlternateIcon sx={{ color: "gray", fontSize: "5rem" }} />
							<Typography
								variant='subtitle2'
								sx={{ color: "gray" }}>
								Add Image
							</Typography>
						</Button>
					)}
				</Box>
			</Box>
		);
	};

	const ViewModifyImageModal = () => {
		useEffect(() => {
			console.log(SelectedLocation);
		}, [SelectedLocation]);

		return (
			<Modal
				open={LocationImageModal}
				onClose={handleLocationImageModalClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<Box
						sx={{
							backgroundColor: "#0C0354",
							p: 1,
							display: "flex",
							gap: 1,
							alignItems: "center",
						}}>
						<IconButton
							aria-label='close modal'
							onClick={() => handleLocationImageModalClose()}>
							<CloseIcon sx={{ color: "white", fontSize: "1rem!important" }} />
						</IconButton>
						<Typography
							color={"white"}
							variant='subtitle2'
							fontSize={"16px"}
							fontWeight={"bold"}>
							View/Modify Site Image/s
						</Typography>
					</Box>
					<Box
						sx={{
							p: 4,
							display: "flex",
							flexDirection: "row",
							overflow: "auto",
							gap: 1,
							margin: 4,
							border: "solid 2px gainsboro",
							borderRadius: "16px",
						}}>
						{SelectedLocation?.image_list.length > 0 &&
							SelectedLocation.image_list.map((image) => {
								return <ImageBox imageURL={process.env.REACT_APP_FILE_PATH + image} />;
							})}
						<ImageBox />
					</Box>
				</Box>
			</Modal>
		);
	};

	const columns = [
		{
			field: "location_name",
			headerName: "Location Name",
			width: 200,
			cardHeaderClasses: "header-sticky",
			// renderCell: (params) => (
			// 	<Box sx={{ position: "sticky", left: 0 }}>
			// 		<Typography>{params.value}</Typography>
			// 	</Box>
			// ),
		},
		{
			field: "address",
			headerName: "Address",
			width: 300,
			headerAlign: "center",
			renderCell: (params) => <AddressCell params={params} />,
		},

		{
			field: "coordinates",
			headerName: "Coordinates",
			width: 250,
			// editable: true,
			sortable: false,
			headerAlign: "center",
			renderCell: (params) => <CoordinatesCell params={params} />,
		},
		{
			field: "status",
			headerName: "Status",
			width: 150,
			headerAlign: "center",
			renderCell: (params) => (
				<Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
					{console.log(params)}
					<Chip
						label={params.value ? "ACTIVE" : "INACTIVE"}
						sx={{ fontWeight: "bold" }}
						color={params.value ? "success" : "error"}
						variant='outlined'
					/>
				</Box>
			),
			// editable: true,
		},

		{
			field: "image_list",
			headerName: "Site Images",
			description: "This column has a value getter and is not sortable.",
			sortable: false,
			width: 250,
			headerAlign: "center",
			renderCell: (params) => <ImageListCell params={params} />,
		},

		{
			field: "evse_count",
			headerName: "EVSEs",
			headerAlign: "center",
			type: "number",
			width: 200,
			renderCell: (params) => <EVSEListCell params={params} />,
		},
		{
			field: "facilities",
			headerAlign: "center",
			headerName: "Facilities",
			width: 250,
			renderCell: (params) => <ListToolTipCell params={params} />,
		},

		{
			field: "parking_types",
			headerName: "Parking Types",
			width: 200,
			renderCell: (params) => <ListToolTipCell params={params} />,
		},
	];

	useEffect(() => {
		// cookies, setCookie, limit, offset, orderBy, order;
		let request = GetLocationList(cookies, setCookie, Limit, OffSet);
		request.then((res) => {
			console.log(res);
			// id, location_name, address, coordinates, image_list, status, evse_count, facilities, parking_types
			const rows = [];
			res.data.locations?.map((location) => {
				const rowData = createData(
					location.id,
					location.name,
					{
						address: location.address,
						city: location.city,
						country_code: location.country_code,
						region: location.region,
						province: location.province,
						postal_code: location.postal_code,
					},
					{
						lat: location.address_lat,
						lng: location.address_lng,
					},
					location.images,
					location.publish,
					location.evses.length,
					location.facilities,
					location.parking_types
				);
				rows.push(rowData);
			});
			setLocationList(rows);
		});
	}, []);

	useEffect(() => {
		console.log("[LOCATION LIST]: ", LocationList);
	}, [LocationList]);

	return (
		<>
			<ViewModifyImageModal />

			<Box sx={{ height: 600, width: "100%" }}>
				<StyledDataGrid
					rows={LocationList}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 10,
							},
						},
						pinnedColumns: { left: ["id"] },
					}}
					getRowHeight={() => "auto"}
					pageSizeOptions={[10]}
					checkboxSelection
					disableRowSelectionOnClick
				/>
			</Box>
		</>
	);
};

export default LocationTable;
