import React from "react";
import UpdateInfo from "./UpdateDataStatus/UpdateInfo";
import { useNavigate  } from "react-router-dom";
import Swal from "sweetalert2";
const CPORegisteredList = (props) => {
const navigate = useNavigate();
const handleLogout = () => {
    // Clear the entire localStorage
    Swal.fire({
        title: "Hi! Admin",
        text: "Are you sure you want to log out?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      }).then((result) => {
        if (result.isConfirmed) {
            localStorage.clear();
            navigate(0);
        }
      });
};

    return (
        <div>
            <div className="card border-0 p-4 mb-4">
                <div className="d-flex justify-content-between">
                    <div>
                        <h3>Hi ! Admin</h3>
                    </div>
                    <div>
                        <button className="btn btn-warning" onClick={handleLogout}>Logout</button>
                    </div>
                </div>
            </div>
            <>
               {props.page === 'Home' ?
                    <div className="card p-4" style={{width:'95%'}}>
                        <center>
                            <img src={props.Logo} className="img-fluid w-50 mb-3" />
                            <h1>ADMINISTRATION</h1>
                        </center>
                    </div> 
                : 
                props.page === 'Update' ? 
                    <UpdateInfo 
                        update_logo_status_approved = {props.update_logo_status_approved}
                        update_logo_status_rejected = {props.update_logo_status_rejected}

                        update_website_status_approved = {props.update_website_status_approved}
                        update_website_status_rejected = {props.update_website_status_rejected}

                        update_tac_status_approved = {props.update_tac_status_approved}
                        update_tac_status_rejected = {props.update_tac_status_rejected}

                        cpoId = {props.cpoId}

                    /> 
                :
                    <div className="card p-4" style={{width:'95%'}}>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                <th scope="col">No</th>
                                <th scope="col">CPO ID</th>
                                <th scope="col">Party_id</th>
                                <th scope="col">Date Created</th>
                                <th scope="col">Admin Modified</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                                <th scope="col">Setting</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.cpoList.map((value, index) => (
                                    <tr>
                                        <th scope="row">{index + 1}</th>
                                        <th>{value.id}</th>
                                        <td><b>{value.party_id}</b></td>
                                        <td></td>
                                        <td></td>
                                        <td className={value.status === 'PENDING' ? 'text-warning' : value.status === 'APPROVED' ? 'text-success' : 'text-warning'}><b>{value.status}</b></td>
                                        <td>
                                            {value.status === 'PENDING' ? 
                                                <><b className="btn btn-success"><i className="bi bi-check2-all"></i></b> &nbsp; <b className="btn btn-danger"><i className="bi bi-x"></i></b></>:<></>
                                            }
                                        </td>
                                        <td><b className="btn btn-primary" onClick={()=>props.btn_Get_CPO_Id(value, index)}><i className="bi bi-gear"></i></b></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>                   
                }
            </>
        </div>
       
    )
};

export default CPORegisteredList;