import React from "react"
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

const FormThree = (props) => {
    return(
        <div className="p-4">
            <form className='p-4'>
                <div className="row mb-2">
                    <div  className="col-sm">
                        <div className="row mb-4">
                            <div  className="col-sm">
                                <FormControl sx={{ minWidth: '100%' }}>
                                <InputLabel 
                                    id="demo-simple-select-helper-label"
                                    error={props.isErrorPageThree && props.power_type === '' ? true : false}
                                    >Power Type</InputLabel>
                                <Select
                                    error={props.isErrorPageThree && props.power_type === '' ? true : false}
                                    className='mb-3 w-100'
                                    label="power_type"
                                    type="text"
                                    name='power_type'
                                    value={props.power_type} 
                                    onChange={props.handleInputChange}
                                >
                                
                                    {props.powerTypesList.map((types) => (
                                    <MenuItem value={types.power_type}>
                                        {types.power_type}
                                    </MenuItem>
                                    ))}
                                </Select>
                                </FormControl>
                            </div>
                            <div  className="col-sm">
                                <TextField
                                    InputProps={{
                                        maxLength: 5, // Set your desired maximum length here
                                    }} 
                                    error={props.isErrorPageThree && props.max_voltage === '' ? true : false}
                                    className='w-100 mb-3'
                                    label="Max Voltage"
                                    type="number"
                                    name="max_voltage"
                                    value={props.max_voltage}
                                    onChange={props.handleInputChange}
                                   
                                    
                                /> 
                            </div>
                            <div  className="col-sm">
                                <TextField 
                                    error={props.isErrorPageThree && props.max_amperage === '' ? true : false}
                                    className='w-100 mb-3'
                                    label="Max Amperage"
                                    type="text"
                                    name="max_amperage"
                                    value={props.max_amperage}
                                    onChange={props.handleInputChange}
                                /> 
                            </div>
                            <div  className="col-sm">
                                <TextField
                                    error={props.isErrorPageThree && props.max_electric_power === '' ? true : false} 
                                    className='w-100 mb-3'
                                    label="Max Electric Power"
                                    type="number"
                                    name="max_electric_power"
                                    value={props.max_electric_power}
                                    onChange={props.handleInputChange}
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-sm-8 mb-3'>
                            <small className="form-text text-muted">
                                <h3>EVSE Connectors</h3>
                                <i className='d-block'> Reuse the plus sign and minus sign to add and remove connectors, 1-3 only</i>
                            </small>
                            {props.evseConnectors.map((x, i) => {
                                return ( 
                                    <div className="row mb-1">
                                        <div className="col-lg-5">
                                            <div className="mb-1">
                                            <FormControl sx={{ minWidth: '100%' }}>
                                            <InputLabel 
                                                id="demo-simple-select-helper-label"
                                                error={props.isErrorPageThree && props.evseConnectors[i].type === '' ? true : false}
                                                >Connector Types</InputLabel>
                                            <Select
                                                error={props.isErrorPageThree && props.evseConnectors[i].type === '' ? true : false}
                                                className='w-100'
                                                label="Connector Types"
                                                type="text"
                                                name='type'
                                                value={x.type}
                                                onChange={e => props.handleEvseConnectors(e, i)}
                                            >
                                            
                                                {props.connectorTypesList.map((connectors) => (
                                                <MenuItem value={connectors.connector_type}>
                                                    {connectors.connector_type}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                            
                                            </div>
                                        </div>
                                        <div className="col-lg-5 mb-1">
                                            <div className="mb-1"> 
                                            <FormControl sx={{ minWidth: '100%' }}>
                                            <InputLabel 
                                                id="demo-simple-select-helper-label"
                                                error={props.isErrorPageThree && props.evseConnectors[i].format === '' ? true : false}
                                                >Connector Format</InputLabel>
                                            <Select
                                                error={props.isErrorPageThree && props.evseConnectors[i].format === '' ? true : false}
                                                className='w-100'
                                                label="Connector Format"
                                                type="text"
                                                name='format'
                                                value={x.format}
                                                onChange={e => props.handleEvseConnectors(e, i)}
                                            >
                                            
                                                {props.connectorFormatList.map((connectors) => (
                                                <MenuItem value={connectors.connector_format}>
                                                    {connectors.connector_format}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>                       
                                            </div>
                                        </div>
                                        <div className="col-lg-2 d-flex justify-content-around mb-3">
                                            <>
                                            {
                                                props.evseConnectors.length !== 1 && 
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => props.handleRemoveClick(i)}> 
                                                        - 
                                                </button>
                                            }
                                            </>

                                            <>
                                            {
                                                props.evseConnectors.length - 1 === i && props.evseConnectors.length !== 3 &&
                                                <button 
                                                    type="button" 
                                                    className="btn btn-primary" 
                                                    onClick={props.handleAddClick}> 
                                                        +
                                                </button>
                                            }
                                            </>
                                        </div>
                                    </div>
                                    );
                            })}   
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-sm-4 card p-4">
                        <label className="mb-1 h5">PRICES</label><br/>
                        <label className="txt2 mb-4"></label>
                        <div className="mb-2">
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel 
                                    htmlFor="outlined-adornment-amount"
                                    error={props.isErrorPageThree && props.electricity_fee === null ? true : false}>Electricity Fee</InputLabel>
                                <OutlinedInput
                                    error={props.isErrorPageThree && props.electricity_fee === null ? true : false} 
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">₱</InputAdornment>}
                                    placeholder="0"
                                    type="number"
                                    label="Electricity Fee"
                                    name='electricity_fee'
                                    value={props.electricity_fee} 
                                    onChange={props.handleInputPrices} 
                                    endAdornment={<InputAdornment position="end">.00</InputAdornment>}
                                />
                            </FormControl>
                        </div>
                        <div className="mb-2">
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel 
                                    htmlFor="outlined-adornment-amount"
                                    error={props.isErrorPageThree && props.service_fee === null ? true : false}>Service Fee</InputLabel>
                                <OutlinedInput
                                    error={props.isErrorPageThree && props.service_fee === null ? true : false} 
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">₱</InputAdornment>}
                                    placeholder="0"
                                    type="number"
                                    label="Service Fee"
                                    name='service_fee'
                                    value={props.service_fee} 
                                    onChange={props.handleInputPrices} 
                                   
                                    
                                    endAdornment={<InputAdornment position="end">.00</InputAdornment>}
                                />
                            </FormControl>
                        </div>
                        <div className="mb-2">
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel 
                                    htmlFor="outlined-adornment-amount"
                                    error={props.isErrorPageThree && props.maintenance_fee === null ? true : false}>Maintenance Fee</InputLabel>
                                <OutlinedInput
                                    error={props.isErrorPageThree && props.maintenance_fee === null ? true : false} 
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">₱</InputAdornment>}
                                    placeholder="0"
                                    type="number"
                                    label="Maintenance Fee"
                                    name='maintenance_fee'
                                    value={props.maintenance_fee} 
                                    onChange={props.handleInputPrices} 
                                    endAdornment={<InputAdornment position="end">.00</InputAdornment>}
                                />
                            </FormControl>
                        </div>
                        <div className="mb-2">
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel 
                                    htmlFor="outlined-adornment-amount"
                                    error={props.isErrorPageThree && props.admin_fee === null ? true : false}>Admin Fee</InputLabel>
                                <OutlinedInput
                                    error={props.isErrorPageThree && props.admin_fee === null ? true : false} 
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">₱</InputAdornment>}
                                    placeholder="0"
                                    type="number"
                                    label="Admin Fee"
                                    name='admin_fee'
                                    value={props.admin_fee} 
                                    onChange={props.handleInputPrices} 
                                    endAdornment={<InputAdornment position="end">.00</InputAdornment>}
                                />
                            </FormControl>
                        </div>
                        <div className="mb-2">
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel 
                                    htmlFor="outlined-adornment-amount"
                                    error={props.isErrorPageThree && props.parking_fee === null ? true : false}>Parking Fee</InputLabel>
                                <OutlinedInput
                                    error={props.isErrorPageThree && props.parking_fee === null ? true : false} 
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">₱</InputAdornment>}
                                    placeholder="0"
                                    type="number"
                                    label="Parking Fee"
                                    name='parking_fee'
                                    value={props.parking_fee} 
                                    onChange={props.handleInputPrices} 
                                    endAdornment={<InputAdornment position="end">.00</InputAdornment>}
                                />
                            </FormControl>
                        </div>
                        <div className="mb-3">
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel 
                                    htmlFor="outlined-adornment-amount"
                                    error={props.isErrorPageThree && props.vat === null ? true : false}>VAT</InputLabel>
                                <OutlinedInput
                                    error={props.isErrorPageThree && props.vat === null ? true : false} 
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">₱</InputAdornment>}
                                    placeholder="0"
                                    type="number"
                                    label="VAT"
                                    name='vat'
                                    value={props.vat} 
                                    onChange={props.handleInputPrices} 
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                />
                            </FormControl>
                        </div>
                        <div className="d-flex justify-content-between p-2 h4">
                            <b>Total Fee</b>
                            <b>₱ {props.total_prices}.00</b>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default FormThree;