import React, { useState, useEffect } from "react";

const UpdateInfo = (props) => {

    return (
    <>
        <div className="card p-4" style={{width:'95%'}}>
            <p className="text-left">CPO ID : {props.cpoId}</p>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Title</th>
                        <th scope="col">Data</th>
                        <th scope="col">Action</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>

                        <td>Company Logo</td>  
                        <td></td>
                        <td>
                            <b className="btn btn-success" onClick={props.update_logo_status_approved}><i className="bi bi-check2-all"></i></b> &nbsp; 
                            <b className="btn btn-danger" onClick={props.update_logo_status_rejected}><i className="bi bi-x"></i></b>
                        </td>
                        <td></td>                        
                    </tr>
                    <tr>
                        <td>Company Website</td>
                        <td></td>
                        <td>
                            <b className="btn btn-success" onClick={props.update_website_status_approved}><i className="bi bi-check2-all"></i></b> &nbsp; 
                            <b className="btn btn-danger"  onClick={props.update_website_status_rejected}><i className="bi bi-x"></i></b>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Terms and Conditions</td>    
                        <td></td>
                        <td>
                            <b className="btn btn-success" onClick={props.update_tac_status_approved}><i className="bi bi-check2-all"></i></b> &nbsp; 
                            <b className="btn btn-danger"  onClick={props.update_tac_status_rejected}><i className="bi bi-x"></i></b>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>
    )
};

export default UpdateInfo;