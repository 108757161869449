import React, { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Tooltip, Typography, Button, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import Logo from "../../Assets/images/logo.png";
import html2canvas from "html2canvas";

import RegisterCompany from "../../../Functions/RegisterCompany";
// import { Button } from "bootstrap";

const Register = (props) => {
	const companyLogoRef = useRef();
	const navigate = useNavigate();
	const [IsLoading, setIsLoading] = useState(false);
	const [registrationStep, setRegistrationStep] = useState("step_form");
	const [companyName, setCompanyName] = useState("");
	const [ownerName, setOwnerName] = useState("");
	const [address, setAddress] = useState("");
	const [contactName, setContactName] = useState("");
	const [contactNumber, setContactNumber] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	const [username, setUsername] = useState("");
	const [isOCPPready, setIsOCPPready] = useState(false);

	const [errorRegister, setErrorRegister] = useState();
	const [isRequiredInput, setIsRequiredInput] = useState();
	const [isErrorResponse, setisErrorResponse] = useState(false);
	const [CompanyLogo, setCompanyLogo] = useState(null);
	const [DisplayCompanyLogo, setDisplayCompanyLogo] = useState();

	const [OwnerNameError, setOwnerNameError] = useState("");
	const [UsernameError, setUsernameError] = useState("");
	const [ContactNameError, setContactNameError] = useState("");
	const [ContactEmailError, setContactEmailError] = useState("");
	const [ContactNumberError, setContactNumberError] = useState("");

	useEffect(() => {
		console.log(CompanyLogo);
	}, [CompanyLogo]);

	useEffect(() => {
		if (DisplayCompanyLogo !== undefined) {
			handleExportAsPng();
		}
		console.log(DisplayCompanyLogo);
	}, [DisplayCompanyLogo]);

	function handleChange(e) {
		console.log(e.target.files);
		setDisplayCompanyLogo(e.target.files[0]);
	}

	const toggleOCPPcheckbox = () => {
		if (isOCPPready) {
			setIsOCPPready(false);
		} else {
			setIsOCPPready(true);
		}
	};

	const toggleLoginPage = () => {
		if (props.toLogin !== undefined) {
			props.toLogin();
		}

		navigate("/");
	};

	const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/; // EMAIL ADDRESS REGEX
	const phoneNumberRegexPH = /^(09|\+63)?[0-9]{10,}$/; // PHONE NUMBER PH REGEX

	//============================================================================================= 2.2. Function to handle CPO registration form submission
	const RegisterSubmit = async (e) => {
		e.preventDefault();
		// Check if any required input is empty or if there are validation errors
		if (ownerName === "" || contactName === "" || contactEmail === "" || contactNumber === "" || username === "") {
			setIsRequiredInput(true); // Set state to indicate required input is missing
			if (!phoneNumberRegexPH.test(contactNumber) || !emailRegex.test(contactEmail)) {
				// Check for specific validation errors (phone number and email)
				setErrorRegister(true); // Set state to indicate validation error
			}
		} else {
			setIsLoading(true);
			let request = RegisterCompany(
				ownerName,
				username,
				contactName,
				contactNumber,
				contactEmail,
				isOCPPready,
				CompanyLogo
			);
			request.then((res) => {
				if (res.status === 200) {
					setRegistrationStep("step_success");
				} else {
					if (res.message === "CONTACT_NUMBER_EXISTS") {
						setContactNumberError("already_exist");
					} else if (res.message === "USERNAME_EXISTS") {
						setUsernameError("already_exist");
					} else {
						if (res.message === "CONTACT_NUMBER_EXISTS;") {
							setContactNumberError("already_exist");
						}
					}
				}
				setIsLoading(false);
			});
		}
	};

	const dataURLtoFile = (dataurl, filename) => {
		var arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[arr.length - 1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	};

	const handleExportAsPng = () => {
		const element = companyLogoRef.current;
		html2canvas(element, { backgroundColor: null }).then((canvas) => {
			const dataUrl = canvas.toDataURL("image/png");
			const filename = "company_logo_" + Date.now() + ".png";
			const imageFile = dataURLtoFile(dataUrl, filename);
			setCompanyLogo(imageFile);
		});
	};

	useEffect(() => {
		if (
			ownerName !== "" &&
			OwnerNameError === "" &&
			contactName !== "" &&
			ContactNameError === "" &&
			contactEmail !== "" &&
			ContactEmailError === "" &&
			contactNumber !== "" &&
			ContactNumberError === "" &&
			username !== "" &&
			UsernameError === ""
		) {
			setErrorRegister(false);
		} else {
			setErrorRegister(true);
		}
	}, [OwnerNameError, ContactNameError, ContactEmailError, ContactNumberError, UsernameError]);

	useEffect(() => {
		console.log(errorRegister);
	}, [errorRegister]);

	const handleInputChange = (e, field) => {
		let value = e.target.value;
		if (field === "owner_name") {
			setOwnerName(value);
			if (value === "") {
				setOwnerNameError("is_empty");
			} else {
				setOwnerNameError("");
			}
		}

		if (field === "username") {
			setUsername(value);
			if (value === "") {
				setUsernameError("is_empty");
			} else {
				setUsernameError("");
			}
		}

		if (field === "contact_name") {
			setContactName(value);
			if (value === "") {
				setContactNameError("is_empty");
			} else {
				setContactNameError("");
			}
		}

		if (field === "contact_email") {
			setContactEmail(value);
			if (value === "") {
				setContactEmailError("is_empty");
			} else {
				if (!emailRegex.test(value)) {
					setContactEmailError("is_not_valid");
				} else {
					setContactEmailError("");
				}
			}
		}

		if (field === "contact_number") {
			setContactNumber(value);
			if (value === "") {
				setContactNumberError("is_empty");
			} else {
				if (!phoneNumberRegexPH.test(value)) {
					setContactNumberError("is_not_valid");
				} else {
					setContactNumberError("");
				}
			}
		}
	};

	return (
		<div className='limiter'>
			<div className='container-login100'>
				<div
					className='wrap-login100'
					style={{ height: "max-content", display: "flex", flexDirection: "column" }}>
					<span className='login100-form-title p-b-43'>
						<img
							src={Logo}
							className='img-fluid'
							style={{ scale: ".75" }}
						/>
					</span>
					{registrationStep === "step_form" ? (
						<form
							className='login100-form validate-form'
							style={{ height: "100%", marginBottom: "24px" }}>
							<h5 className='d-flex flex-column text-center mb-2'>
								<b>eMobility Service Provider (eMSP)</b>
								<b> Registration</b>
							</h5>
							<Box sx={{ my: 5, border: "solid gray .5px", p: 2, borderRadius: "8px" }}>
								<Typography
									variant='subtitle2'
									sx={{ fontWeight: "bold", fontSize: "16px" }}>
									Important note:
								</Typography>
								<Box sx={{ px: 2, py: 1 }}>
									<Typography
										variant='subtitle2'
										sx={{ fontWeight: "bold" }}>
										What is a eMSP?
									</Typography>
									<Typography
										variant='caption'
										sx={{ textAlign: "justify" }}>
										An eMobility Service Provider (EMSP) provides EV charging services to EV owners. eMSP typically
										manages the relationship with its customers, the EV owner/driver, by providing an EV charging app
										and web portal, handling payments and invoicing, and providing customer service when needed.
									</Typography>
								</Box>
							</Box>
							{/* <div className="image-display-holder">
                            <img src={CompanyLogo} />
                        </div> */}
							<Box
								sx={{
									display: "flex",
									alignItems: {
										lg: "flex-end",
										xs: "center",
									},
									flexDirection: {
										lg: "row",
										xs: "column-reverse",
									},
								}}>
								<Box
									sx={{
										width: {
											lg: "75%",
											xs: "100%",
										},
									}}>
									{/* <TextField
									error={isRequiredInput && companyName === "" ? true : false}
									label='Company Name'
									className='form-control mb-3'
									value={companyName}
									onChange={(e) => setCompanyName(e.target.value)}
									helperText={isRequiredInput && companyName === "" && "This field is required"}
								/> */}
									<Typography variant='caption'>* - Required fields</Typography>
									<TextField
										error={OwnerNameError === "is_empty"}
										sx={{
											width: {
												lg: "99%",
												xs: "100%",
											},
											marginTop: "1rem",
										}}
										label='eMSP Company/Brand Name*'
										className='form-control mb-3'
										value={ownerName}
										onChange={(e) => handleInputChange(e, "owner_name")}
										helperText={OwnerNameError === "is_empty" && "This field is required"}
									/>
									{/* <TextField
									error={isRequiredInput && address === "" ? true : false}
									label='Company Address'
									className='form-control mb-3'
									value={address}
									onChange={(e) => setAddress(e.target.value)}
									helperText={isRequiredInput && address === "" && "This field is required"}
								/> */}
									<TextField
										error={UsernameError !== ""}
										sx={{
											width: {
												lg: "99%",
												xs: "100%",
											},
										}}
										label='Username*'
										className='form-control mb-3'
										value={username}
										onChange={(e) => handleInputChange(e, "username")}
										helperText={
											UsernameError === "is_empty"
												? "This field is required"
												: UsernameError === "already_exist" && "An account with this username already exists"
										}
									/>
								</Box>
								<Box
									sx={{
										width: {
											lg: "25%",
											xs: "55%",
										},
										height: "100%",
										paddingLeft: { lg: "8px" },
									}}>
									<label
										style={{ width: "100%", height: "200px", borderColor: "rgba(0, 0, 0, 0.23)" }}
										className='form-control d-flex flex-column align-items-center justify-content-center image-input-holder'
										for='image_input'>
										{DisplayCompanyLogo !== undefined && (
											<Box
												ref={companyLogoRef}
												sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
												<div className='image-display-holder'>
													<Box sx={{ borderRadius: "16px" }}>
														<img
															src={URL.createObjectURL(DisplayCompanyLogo)}
															className='image-display'
														/>
													</Box>
												</div>
												<div class='down-arrow'></div>
											</Box>
										)}
										{DisplayCompanyLogo !== undefined ? (
											<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
												<label
													className='mt-2'
													id='insert_image'
													for='image_input'>
													Replace
												</label>
												<Typography variant='subtitle2'>(60px * 60px)</Typography>
											</Box>
										) : (
											<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
												<label
													id='insert_image'
													for='image_input'>
													<AddToPhotosIcon />
													Company Logo
												</label>
												<Typography variant='subtitle2'>(60px * 60px)</Typography>
											</Box>
										)}

										<input
											type='file'
											className='registration-image-input'
											id='image_input'
											onChange={handleChange}
										/>
									</label>
								</Box>
							</Box>

							<TextField
								error={ContactNameError === "is_empty"}
								label='Contact Name*'
								className='form-control mb-3'
								value={contactName}
								onChange={(e) => handleInputChange(e, "contact_name")}
								helperText={ContactNameError === "is_empty" && "This field is required"}
							/>
							<div className='row'>
								<div className='col-sm mb-3'>
									<TextField
										error={ContactEmailError !== ""}
										label='Contact Email Address*'
										className='form-control'
										value={contactEmail}
										type='email'
										onChange={(e) => handleInputChange(e, "contact_email")}
										helperText={
											ContactEmailError === "is_empty"
												? "This field is required"
												: ContactEmailError === "is_not_valid"
												? "Invalid Format: The email address must be in user@example.com or user@example.com.ph"
												: ContactEmailError === "already_exist" && "An account with this email address already exists"
										}
									/>
								</div>
								<div className='col-sm'>
									<InputMask
										mask='+639999999999'
										maskChar=''
										value={contactNumber}
										onChange={(e) => handleInputChange(e, "contact_number")}>
										{(inputProps) => (
											<TextField
												{...inputProps}
												error={ContactNumberError !== ""}
												fullWidth
												label='Contact Number*'
												variant='outlined'
												placeholder='+639*********'
												helperText={
													ContactNumberError === "is_empty"
														? "This field is required"
														: ContactNumberError === "is_not_valid"
														? "Invalid Format: The phone number must be in 912 345 6789 and starts 9"
														: ContactNumberError === "already_exist" &&
														  "An account with this email address already exists"
												}
											/>
										)}
									</InputMask>
									{/* {contactNumber.slice(3)}  */}
								</div>
							</div>

							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
								}}
								className='mb-3'>
								{/* <FormControlLabel
									control={<Checkbox />}
									sx={{ alignItems: "center" }}
									value={isOCPPready}
									onChange={toggleOCPPcheckbox}
									label={
										<Typography>
											OCPP Ready{" "}
											<Tooltip title='The OCPP (Open Charge Point Protocol) is an application protocol for communication between Electric vehicle charging stations and a central management system, also known as a charging station network, similar to cell phones and cell phone networks.'>
												<InfoIcon sx={{ color: "gray" }} />
											</Tooltip>
										</Typography>
									}
								/> */}
								<FormControlLabel
									control={<Checkbox />}
									label='Agree to Terms and Privacy Condition'
								/>
							</Box>
							<div className='container-login100-form-btn mb-3'>
								<button
									id='sign_up_btn'
									disabled={errorRegister === undefined || errorRegister || IsLoading}
									className='login100-form-btn'
									style={{ color: (errorRegister === undefined || errorRegister) && "gray" }}
									onClick={RegisterSubmit}>
									{IsLoading ? (
										<CircularProgress
											size={"1.5rem"}
											color='inherit'
										/>
									) : (
										"Sign Up"
									)}
								</button>
							</div>
							<center>
								<div>
									Already have an account? &nbsp;
									<a
										href='#'
										onClick={toggleLoginPage}>
										<b>Sign In</b>
									</a>
								</div>
							</center>
						</form>
					) : (
						<Box
							sx={{
								width: {
									lg: "50%",
									xs: "90%",
								},
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: 2,
								marginTop: "16px",
							}}>
							<Typography
								variant='subtitle2'
								sx={{ textAlign: "center", fontSize: { lg: "24px", xs: "16px" } }}>
								Congratulations! Your eMSP registration has been successfully completed. We are thrilled to welcome you
								as our partner.
							</Typography>
							<Typography
								variant='subtitle2'
								sx={{ textAlign: "center", fontSize: { lg: "24px", xs: "16px" } }}>
								To finalize your partnership with us, please check your registered email address for further
								instructions on the next steps. This email contains all the necessary details and information you need
								to proceed.
							</Typography>
							<Typography
								variant='subtitle2'
								sx={{ textAlign: "center", fontSize: { lg: "24px", xs: "16px" } }}>
								If you have any questions or need assistance at any point, do not hesitate to reach out to our support
								team. We are here to help you every step of the way. Thank you for joining us, and we look forward to a
								successful collaboration!
							</Typography>
							<Typography
								variant='subtitle2'
								sx={{ textAlign: "center", fontSize: { lg: "24px", xs: "16px" } }}>
								Thank you for joining us, and we look forward to a successful collaboration!
							</Typography>
							<Button
								sx={{ width: "max-content" }}
								onClick={toggleLoginPage}>
								Back to Login
							</Button>
						</Box>
					)}

					{/* <img src={props.LoginImage} className='img-fluid login100-more'/> */}
				</div>
			</div>
		</div>
	);
};

export default Register;
