import UploadLogo from "./UploadLogo";

const RegisterCompany = async (cpo_owner_name, username, contact_name, contact_number, contact_email, ocpp_ready, logo) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + process.env.REACT_APP_BASIC_TOKEN);
    myHeaders.append("Content-Type", "application/json");

    console.log(logo)
    const raw = JSON.stringify({
        "cpo_owner_name": cpo_owner_name,
        "username": username,
        "contact_name": contact_name,
        "contact_number": contact_number,
        "contact_email": contact_email,
        "ocpp_ready": ocpp_ready,
        "logo": logo === null ? undefined : logo.name
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_SERVICE_URL}/ocpi/cpo/2.2/register`,
            requestOptions
        );

        const result = await response.json();

        if (result.status === 200) {
            if (logo !== null) {
                let request = UploadLogo(logo)
                request.then((res) => {
                    console.log(res);
                })
            }
        }



        return result;
    } catch (error) {
        console.error('Error fetching data: ', error);
    }
}

export default RegisterCompany;