const UploadLogo = async (logo) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + process.env.REACT_APP_BASIC_TOKEN);
    myHeaders.append("Cookie", "vsid=931vr463105325321311629");

    const formdata = new FormData();
    formdata.append("cpo_logo", logo, logo.name);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_SERVICE_URL}/ocpi/cpo/2.2/cpo/upload` , requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error(error);
    }
};

export default UploadLogo;
