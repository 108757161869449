import React from "react";
import { TypeAnimation } from "react-type-animation";
import "../../Assets/css/Home.css";
const Header = (props) => {
	return (
		<div className='hero_area'>
			<div className='hero_bg_box'>
				<div className='bg_img_box'>
					<img
						src={props.HomeBackground}
						alt=''
					/>
				</div>
			</div>

			<header className='header_section'>
				<div
					className='container-fluid card'
					style={{ padding: "unset!important" }}>
					<div className='d-flex justify-content-between'>
						<nav className='navbar navbar-expand-lg custom_nav-container '>
							<a
								className='navbar-brand'
								href='#'>
								<img
									src={props.Logo}
									className='home-header-img'
								/>
							</a>
						</nav>
						<button className='btn'>
							<b
								className=' btn btn-danger'
								onClick={() => props.onSetLoggedOut()}>
								<i className='bi bi-box-arrow-right' />
								<span className='d-none d-md-inline'>&nbsp;Logout</span>
							</b>
						</button>
					</div>
				</div>
			</header>
			<section className='slider_section'>
				<div
					id='customCarousel1'
					className='carousel slide'
					data-ride='carousel'>
					<div className='carousel-inner'>
						<div className='carousel-item active'>
							<div className='container '>
								<div className='row'>
									<div className='col-md-6 '>
										<div className='detail-box'>
											<h3 className='text-white text-lg-start text-center'>
												<TypeAnimation
													sequence={[
														`WELCOME eMSP Partner!`,
														1000,
														"YOU ARE ON FIND EV PLUG",
														1000,
														1000,
														"",
														1000,
														"YOUR PARTNER FOR EV SOLUTIONS",
														1000,
														1000,
													]}
													wrapper='span'
													speed={50}
													style={{ fontSize: "1em", display: "block", whiteSpace: "pre-line" }}
													repeat={Infinity}
												/>
											</h3>
											{/* <p>For registration of new EV Charging Location Site.</p>
											<div className='btn-box'>
												<button
													className='btn btn-primary'
													onClick={props.toLocationSiteRegister}>
													<i className='bi bi-file-text'></i> Register Here
												</button>
											</div> */}
										</div>
									</div>
									<div className='col-md-6'>
										<div
											className='img-box'
											style={{ margin: "unset!important" }}>
											<img
												src={props.HeaderImage}
												alt=''
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};
export default Header;
