import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "../Assets/css/Home.css";
import "../Assets/css/Spinner.css";

import Header from "./Includes/Header";
import Details from "./CPO/Details";
import LocationSiteList from "./Location/LocationRegistered/LocationSiteList";
import LocationRegister from "./Location/LocationRegistration/LocationSiteRegister";
import HomeBackground from "../Assets/images/bg.png";
import Footer from "./Includes/Footer";
import Logo from "../Assets/images/logo.png";
import HeaderImage from "../Assets/images/headerImg.png";

import Swal from "sweetalert2";

import Logout from "../../Functions/Logout";

import LocationEVSETab from "./Components/LocationEVSETab";
import TokenAPIsTab from "./Components/TokenAPIsTab";

const Home = (props) => {
  const [cookies, removeCookie, setCookie] = useCookies(["refresh_token", "access_token"]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(cookies);
  }, []);

  useEffect(() => {
    if (props.onIfLoggedIn) {
      setLoading(false);
    }
  }, [props]);

  const [parkingTypesList, setParkingTypes] = useState([]);
  const [chargingCapabilitiesList, setChargingCapabilitiesListList] = useState([]);
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [parkingRestrictionsList, setParkingRestrictionsList] = useState([]);
  const [powerTypesList, setPowerTypesList] = useState([]);
  const [connectorTypesList, setConnectorTypesList] = useState([]);
  const [connectorFormatList, setConnectorFormatList] = useState([]);
  const [TokenC, setTokenC] = useState("");

  const pageLimit = 5;
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(null);
  const [totalPage, setTotalPage] = useState(null);
  const [locationSiteRegisteredData, setLocationSiteRegisteredData] = useState([]);
  const [isPage, setIsPage] = useState("home");

  //========================================================================================= 6 Location Site Registered Data
  //========================================================================================= It is likely used in a React component to fetch and handle data from an API endpoint.

  const totalPages = Math.ceil(totalData / pageLimit);
  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setPage(page);
    }
  };

  const toggleLogout = () => {
    Swal.fire({
      title: "Close this app",
      text: "Are you sure you want to logout?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        confirmButton: "order-1 right-gap",
        denyButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        let request = Logout(cookies.auth_token);
        request.then((res) => {
          if (res.status === 200) {
            props.onClearCookies();
            navigate("/");
          }
        })

      } else {
        navigate("/");
      }
    });
  };

  const toLocationSiteRegister = () => {
    setIsPage("register");
  };
  const toHome = () => {
    setIsPage("home");
  };

  const handleTokenC = (token) => {
    setTokenC(token)
  }

  return (
    <div>
      {loading ? (
        <div className='spinner-container'>
          <div className='spinner'></div>
        </div>
      ) : (
        <>
          {isPage === "home" ? (
            <>
              <Header
                HomeBackground={HomeBackground}
                Logo={Logo}
                HeaderImage={HeaderImage}
                onSetLoggedOut={toggleLogout}
                toLocationSiteRegister={toLocationSiteRegister}
              />
              <Details onSetTokenC={handleTokenC} />
              <LocationEVSETab />
              <TokenAPIsTab onTokenC={TokenC} />
              {/* <LocationSiteList 
                        parkingTypesList = {parkingTypesList}
                        parkingRestrictionsList = {parkingRestrictionsList}
                        chargingCapabilitiesList = {chargingCapabilitiesList}
                        powerTypesList = {powerTypesList}
                        facilitiesList = {facilitiesList}
                        connectorTypesList = {connectorTypesList}
                        connectorFormatList = {connectorFormatList}

                        LocationSiteRegisterList = {LocationSiteRegisterList}
                        locationSiteRegisteredData = {locationSiteRegisteredData}
                        totalData = {totalData}
                        totalPage = {totalPage}
                        setPage = {setPage}
                        pageLimit = {pageLimit}
                        page = {page}
                        goToPage = {goToPage}
                    /> */}
            </>
          ) : (
            <LocationRegister
              parkingTypesList={parkingTypesList}
              parkingRestrictionsList={parkingRestrictionsList}
              chargingCapabilitiesList={chargingCapabilitiesList}
              powerTypesList={powerTypesList}
              facilitiesList={facilitiesList}
              connectorTypesList={connectorTypesList}
              connectorFormatList={connectorFormatList}
              // LocationSiteRegisterList={LocationSiteRegisterList}
              totalData={totalData}
              toHome={toHome}
              setIsPage={setIsPage}
            />
          )}
          <Footer Logo={Logo} />
        </>
      )}
    </div>
  );
};

export default Home;
