import React, { useState } from "react";
import { CPOLogo } from "../LoginPage";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import IconButton from '@mui/material/IconButton';
import { useLocation } from 'react-router-dom';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import { useCookies } from 'react-cookie';

const EnterPassword = () => {
    const { ResetPassword } = useParams();
    const [cookies, setCookie] = useCookies('');
    const url = new URL(window.location.href);


    const location = useLocation();
    const changeToken = location.search;
    const sliceLocation = changeToken.slice(1);
    const authToken = sliceLocation.slice(1);



    const navigate = useNavigate();
    const [password, setPassword] = useState('')
    const [comfirmPassword, setComfirmPassword] = useState('')
    const [isLengthValid, setIsLengthValid] = useState();
    const [isCaseValid, setIsCaseValid] = useState();
    const [isNumbersValid, setIsNumbersValid] = useState();
    const [isSpecialCharValid, setIsSpecialCharValid] = useState();
    const [msg, setMsg] = useState('')
    const validationRegex = (e) => {
        const value = e.target.value;
        setPassword(value);
        const password_pattern_length = /^.{12,}$/
        const password_pattern_case = /[a-zA-Z]+/
        const password_pattern_number = /[0-9]+/;
        const password_pattern_special_character = /[!@#$%^&*()\[\]]+/

        // Check each condition
        setIsLengthValid(password_pattern_length.test(value));
        setIsCaseValid(password_pattern_case.test(value));
        setIsNumbersValid(password_pattern_number.test(value));
        setIsSpecialCharValid(password_pattern_special_character.test(value));
    }
    //============================================================================================================= 4.3 Function to handle password reset)
    const resetPassword = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior)
        if (password === '' || comfirmPassword === '') { // Check if password or confirm password is empty)
            Swal.fire({
                position: "center",
                icon: "warning",
                html: `
                New Password and Retype New Password are required..
            `,
                showConfirmButton: false,
                timer: 1500
            });
            setMsg('error4'); // Set a message indicating error4 (e.g., password or confirm password is empty))
        } else if (!isLengthValid || !isSpecialCharValid || !isCaseValid || !isNumbersValid) {
            Swal.fire({
                position: "center",
                icon: "warning",
                html: `
                New Password has an invalid format.
            `,
                showConfirmButton: false,
                timer: 1500
            });
            setMsg('error7'); // Set a message indicating error7 (e.g., password length is not valid))
        } else if (password !== comfirmPassword) {
            Swal.fire({
                position: "center",
                icon: "warning",
                html: `
                Confirm Password doesn't match. Try Again !
            `,
                showConfirmButton: false,
                timer: 1500
            });
            setMsg('error6'); // Set a message indicating error6 (e.g., password and confirm password do not match))
        } else {
            await fetch(process.env.REACT_APP_API_ADDRESS + "/nsp/v1/reset-password?" + authToken, { //============ 4.3.1 Make a POST request to reset the password
                method: 'POST',
                body: JSON.stringify({
                    "password": password
                }),
                headers: {
                    'pragma': 'no-cache',
                    'cache-control': 'no-cache',
                    "Authorization": cookies.token_type + " " + cookies.forgot_password_token, // Include the forgot password token in the headers
                    "Content-Type": "application/json"
                },
            })
                .then(response => response.json()) //=================================================================== 4.3.2 Parse the JSON response
                .then((value) => {
                    if (value.status === 200) {
                        //========================================================================================== 4.3.3 Password changed successfully
                        Swal.fire({
                            title: "Password Changed!",
                            icon: "success",
                            html: `
                            Your password has been changed successfully.
                        `,
                            confirmButtonText: "Login",
                        })
                            .then((result) => {
                                if (result.isConfirmed) { //============================================================ 4.3.4 Clear all cookies and navigate to the login page
                                    document.cookie.split(";").forEach((c) => {
                                        document.cookie = c
                                            .replace(/^ +/, "")
                                            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                                    });
                                    navigate('/')
                                }
                            });
                    } else {
                        //========================================================================================== 4.3.5 Password change failed, display an error message
                        Swal.fire({
                            title: "We're sorry!",
                            text: "Please try again!",
                            icon: "error"
                        });
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [RetypeShowPassword, setRetypeShowPassword] = useState(false);
    const handleClickRetypeShowPassword = () => setRetypeShowPassword((show) => !show);

    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <form className="login100-form">
                        <span className="login100-form-title p-b-43">
                            <img src={CPOLogo.Logo} className='img-fluid' />
                        </span>
                        <h5 className="text-center mb-2"><b>Reset Your Password</b></h5>

                        <>
                            {/* STEP 3 : ENTER NEW PASSWORD */}
                            <p>Enter a new password to reset the password on your account. We'll ask for this password whenever you log in.</p>
                            <div className="mb-4">
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password" className={msg === 'error4' ? 'text-danger' : 'text-dark'}>New password</InputLabel>
                                    <OutlinedInput
                                        error={msg === 'error4' ? true : false}
                                        helperText={msg === 'error4' ? 'Please fill out this field' : ''}
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={validationRegex}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="New password"
                                    />
                                </FormControl>
                                <p>
                                    <ul className={!isLengthValid ? 'text-danger' : 'text-success'}>
                                        <b>
                                            {!isLengthValid ?
                                                <i className="bi bi-exclamation-octagon"></i> : <i className="bi bi-check2-all"></i>
                                            }&nbsp;Must be at least 12 characters long.
                                        </b>
                                    </ul>
                                    <ul className={!isCaseValid ? 'text-danger' : 'text-success'}>
                                        <b>
                                            {!isCaseValid ?
                                                <i className="bi bi-exclamation-octagon"></i> : <i className="bi bi-check2-all"></i>
                                            }&nbsp;Must contain an uppercase and lowercase letter (A, z)
                                        </b>
                                    </ul>
                                    <ul className={!isNumbersValid ? 'text-danger' : 'text-success'}>
                                        <b>
                                            {!isNumbersValid ?
                                                <i className="bi bi-exclamation-octagon"></i> : <i className="bi bi-check2-all"></i>
                                            }&nbsp;Must contain a number
                                        </b>
                                    </ul>
                                    <ul className={!isSpecialCharValid ? 'text-danger' : 'text-success'}>
                                        <b>
                                            {!isSpecialCharValid ?
                                                <i className="bi bi-exclamation-octagon"></i> : <i className="bi bi-check2-all"></i>
                                            }&nbsp;Must contain a special character (!, %, #, etc.)
                                        </b>
                                    </ul>
                                </p>
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password" className={msg === 'error4' ? 'text-danger' : 'text-dark'}>Retype new password</InputLabel>
                                    <OutlinedInput
                                        error={msg === 'error4' ? true : false}
                                        helperText={msg === 'error4' ? 'Please fill out this field' : ''}
                                        id="outlined-adornment-password"
                                        type={RetypeShowPassword ? 'text' : 'password'}
                                        value={comfirmPassword}
                                        onChange={((e) => setComfirmPassword(e.target.value))}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickRetypeShowPassword}
                                                    edge="end"
                                                >
                                                    {RetypeShowPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Retype new password"
                                    />
                                </FormControl>
                            </div>
                            <button className="btn btn-primary mb-2" onClick={resetPassword}>
                                Reset Password &nbsp;<i className="bi bi-arrow-right-circle-fill" />
                            </button>
                            <p>To make sure your account is secure. you'll be logged out from other devices once you set the new password.</p>
                        </>
                    </form>
                    {/* <img src={CPOLogo.LoginImage} className='img-fluid login100-more'/> */}
                </div>
            </div>
        </div>
    )
}

export default EnterPassword;