import React, { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import { Button, Typography, Box, IconButton, CircularProgress, Snackbar, Alert } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { display, height, padding } from "@mui/system";

import html2canvas from "html2canvas";
import UpdateLogo from "../../../../Functions/UpdateLogo";

const styleModal = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		// height: "70vh",
		marginRight: "-50%",
		width: "max-content",
		transform: "translate(-50%, -50%)",
		zIndex: "1000",
		padding: "unset",
		display: "flex",
		flexDirection: "column",
		height: "max-content",
	},
};

const Logo = (props) => {
	const DisplayLogoRef = useRef();
	const [cookies, setCookie] = useCookies();
	const [DefaultLogo, setDefaultLogo] = useState();
	const [DisplayLogo, setDisplayLogo] = useState();
	const [CompanyLogo, setCompanyLogo] = useState();
	const [IsLoading, setIsLoading] = useState(false);
	const [IsAlertOpen, setIsAlertOpen] = useState(false);
	const [IsImageLoading, setIsImageLoading] = useState(true);
	const [IsDisplayLogoLoading, setIsDisplayLogoLoading] = useState(false);

	const handleClick = () => {
		setIsAlertOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setIsAlertOpen(false);
	};

	useEffect(() => {
		if (props.company_logo !== null) {
			console.log(props.company_logo);
			setDefaultLogo(props.company_logo);
		}
	}, [props]);

	const afterOpenModal = () => {
		document.body.style.overflow = "hidden";
	};

	useEffect(() => {
		if (DefaultLogo !== undefined) {
			setTimeout(() => {
				setIsImageLoading(false);
			}, 500);
		}
	}, [DefaultLogo]);

	useEffect(() => {
		console.log(DisplayLogo);
		if (DisplayLogo !== undefined) {
			setTimeout(() => {
				setIsDisplayLogoLoading(false);
			}, 500);
		}
	}, [DisplayLogo]);

	useEffect(() => {
		if (IsDisplayLogoLoading === false) {
			handleExportAsPng();
		}
	}, [IsDisplayLogoLoading]);

	function handleChange(e) {
		setIsDisplayLogoLoading(true);
		console.log(e.target.files);
		setDisplayLogo(e.target.files[0]);
	}

	const dataURLtoFile = (dataurl, filename) => {
		var arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[arr.length - 1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	};

	const handleExportAsPng = () => {
		const element = DisplayLogoRef.current;
		console.log(element);
		if (element !== undefined) {
			html2canvas(element, { backgroundColor: null, scale: 1 }).then((canvas) => {
				const dataUrl = canvas.toDataURL("image/png");
				const filename = "company_logo_" + Date.now() + ".png";
				const imageFile = dataURLtoFile(dataUrl, filename);
				setCompanyLogo(imageFile);
			});
		}
	};

	const toggleReset = () => {
		setDisplayLogo();
	};

	const toggleSubmit = () => {
		setIsLoading(true);
		let request = UpdateLogo(cookies, setCookie, CompanyLogo);
		request.then((res) => {
			props.closeModalLogo();
			if (res.status === 200) {
				setIsImageLoading(true);
			}
			setTimeout(() => {
				props.onRefreshDetail();
				setDisplayLogo();
				handleClick();
			}, 1500);

			setIsLoading(false);
			console.log(res);
		});
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				open={IsAlertOpen}
				autoHideDuration={6000}
				onClose={handleClose}>
				<Alert
					onClose={handleClose}
					severity='success'
					variant='filled'
					sx={{ width: "100%" }}>
					Company Logo changed successfully!
				</Alert>
			</Snackbar>
			<Modal
				isOpen={props.modalIsOpenLogo}
				onAfterOpen={afterOpenModal}
				onRequestClose={props.closeModalLogo}
				style={styleModal}>
				<Box
					sx={{
						backgroundColor: "#0C0354",
						p: 1,
						display: "flex",
						gap: 1,
						alignItems: "center",
					}}>
					<IconButton
						aria-label='close modal'
						onClick={() => props.closeModalLogo()}>
						<CloseIcon sx={{ color: "white", fontSize: "1rem!important" }} />
					</IconButton>
					<Typography
						color={"white"}
						variant='subtitle2'
						fontSize={"16px"}
						fontWeight={"bold"}>
						Change Company Logo
					</Typography>
				</Box>
				{/* <div className='d-flex justify-content-between mb-2'>
					<h6></h6>
					<button
						className='btn'
						onClick={props.closeModalLogo}>
						X
					</button>
				</div> */}
				<Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
					<Box sx={{ flex: 1, display: "flex" }}>
						<form
							style={{ width: "100%" }}
							action='/stats'
							encType='multipart/form-data'
							method='post'
							onSubmit={props.SubmitLogo}>
							<div style={{ padding: "16px", margin: "2rem", border: "solid .5px gray", borderRadius: "16px" }}>
								<Box
									sx={{
										mb: 1,
										marginTop: DisplayLogo !== undefined && "16px",
										mx: DisplayLogo !== undefined && "32px",
										display: "flex",
										justifyContent: "center",
									}}>
									{DisplayLogo !== undefined ? (
										<>
											{IsDisplayLogoLoading ? (
												<CircularProgress size={"2rem"} />
											) : (
												<Box
													ref={DisplayLogoRef}
													sx={{
														display: "flex",
														flexDirection: "column",
														alignItems: "center",
														scale: "1.25",
														mx: "32px",
													}}>
													<div className='image-display-holder'>
														<Box sx={{ borderRadius: "16px" }}>
															<img
																src={URL.createObjectURL(DisplayLogo)}
																className='image-display'
															/>
														</Box>
													</div>
													<div class='down-arrow'></div>
												</Box>
											)}
										</>
									) : (
										<img
											alt='preview image'
											style={{ scale: "1.25", width: "100%", marginTop: "16px" }}
											className='productLogo'
											src={DefaultLogo}
										/>
									)}
								</Box>
								<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
									<label className='btn btn-link'>
										<input
											type='file'
											name='uploaded_file'
											onChange={handleChange}
										/>
										Replace Logo
									</label>
								</Box>
							</div>
							{DisplayLogo !== undefined && !IsDisplayLogoLoading && (
								<Box sx={{ display: "flex", width: "100%", padding: "8px", gap: 1 }}>
									<Button
										onClick={toggleReset}
										sx={{ width: "50%", whiteSpace: "nowrap" }}>
										Reset Logo
									</Button>
									<Button
										disabled={IsLoading}
										onClick={() => toggleSubmit()}
										variant='contained'
										sx={{ width: "50%", whiteSpace: "nowrap" }}>
										{IsLoading ? <CircularProgress size={"1.5rem"} /> : "Submit"}
									</Button>
								</Box>
							)}
						</form>
					</Box>
				</Box>
			</Modal>

			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Typography
					variant='subtitle2'
					sx={{
						backgroundColor: "#0C0354",
						color: "white",
						fontWeight: "bold",
						textAlign: "center",
					}}>
					Company Logo
				</Typography>
				<Box sx={{ padding: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
					{IsImageLoading ? (
						<CircularProgress size={"2rem"} />
					) : (
						<img
							style={{
								width: "100%",
								transform:
									DefaultLogo === "https://services-parkncharge.sysnetph.com/uploads/default.svg"
										? "scale(.75)"
										: "scale(1.5)",
							}}
							src={DefaultLogo}
						/>
					)}
				</Box>
				<Button
					sx={{ backgroundColor: "#0C0354", color: "white", borderRadius: "unset" }}
					variant='contained'
					color='info'
					onClick={props.openModalLogo}
					size='small'>
					Change Company Logo
				</Button>
			</Box>
		</>
	);
};
export default Logo;
