import React, {useEffect, useState} from "react"
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import LocationData, { RegionsList, ProvinceList, CityList } from "./LocationData/LocationData";
const FormOne = (props) => {

useEffect(() => {
    LocationData();
}, [])

const selectedRegion = props.selectedRegions
const selectedProvince = props.selectedProvinces
const selectedCity = props.selectedCities
const handleRegionChange = props.handleRegionChange
const handleProvinceChange = props.handleProvinceChange
const handleCityChange = props.handleCityChange

const filterPronvice = ProvinceList.filter(list => list.region === selectedRegion.key);
const filterCities = CityList.filter(list => list.province === selectedProvince.key);

    return(
        <div className="p-4 mb-3">
            <form onSubmit={props.onSubmit} className='p-1'>
                <div className='row'>
{/* ========================================================================= ENTER LOCATION/ BUILDING/ ESTABLISTMENT NAME  */}
                    <div className='col-lg-8'>
                        <TextField 
                            error={props.isErrorPageOne && props.location === '' ? true : false}
                            helperText={props.isErrorPageOne && props.location === '' ? 'Please fill out this field.' : ''}
                            className='mb-3 w-100'
                            label="Location/ Building/ Establishment Name"
                            type="text"
                            name="location"
                            value={props.location}
                            onChange={props.handleInputChange}
                        />
                    </div>
{/* ========================================================================= SELECT A REGIONS */}
                    <div className="col-lg-4">
                        <FormControl sx={{ minWidth: '100%' }}>
                            <InputLabel 
                                error={props.isErrorPageOne && props.selectedRegions.length === 0 ? true : false}
                                id="demo-simple-select-helper-label"
                                >Select a Region
                            </InputLabel>
                            <Select
                                error={props.isErrorPageOne && props.selectedRegions.length === 0 ? true : false}
                                className='w-100 mb-3'
                                label="Select a Regions"
                                type="text"
                                value={selectedRegion}
                                onChange={handleRegionChange}
                            >
                            {RegionsList && (RegionsList.map((region) => (
                                <MenuItem value={region}>
                                    {region.name} - {region.long}
                                </MenuItem>
                            )))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
{/* ========================================================================= SELECT A PROVINCES */}
                    <div className="col-lg-4">
                        {selectedRegion && (
                            <FormControl sx={{ minWidth: '100%' }}>
                                <InputLabel 
                                    error={props.isErrorPageOne && selectedProvince.length === 0 ? true : false}
                                    id="demo-simple-select-helper-label"
                                    >Select a Province</InputLabel>
                                <Select
                                    error={props.isErrorPageOne && selectedProvince.length === 0 ? true : false}
                                    className='w-100 mb-3'
                                    label="Select a Province"
                                    type="text"
                                    value={selectedProvince}
                                    onChange={handleProvinceChange}
                                >
                                    {filterPronvice.map((provinces) => (
                                        <MenuItem value={provinces}>
                                            {provinces.name.toLocaleUpperCase()}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </div>
{/* ========================================================================= SELECT A CITIES */}
                    <div className="col-lg-4">
                       {selectedCity.length !== 0 ? 
                         <FormControl sx={{ minWidth: '100%' }}>
                            <InputLabel 
                                error={props.isErrorPageOne && selectedCity.length === 0 ? true : false}
                                id="demo-simple-select-helper-label"
                                >Select a City</InputLabel>
                            <Select
                                error={props.isErrorPageOne && selectedCity.length === 0 ? true : false}
                                className='w-100 mb-3'
                                label="Select a City"
                                type="text"
                                value={selectedCity}
                                onChange={handleCityChange}
                            >
                                {filterCities.map((city) => (
                                    <MenuItem value={city}>
                                        {city.city ? city.name.toLocaleUpperCase() + " CITY" : city.name.toLocaleUpperCase()}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> 
                        : 
                        <FormControl sx={{ minWidth: '100%' }}>
                            <InputLabel 
                                error={props.isErrorPageOne && selectedCity.length === 0 ? true : false}
                                id="demo-simple-select-helper-label"
                                >Select a City</InputLabel>
                            <Select
                                error={props.isErrorPageOne && selectedCity.length === 0 ? true : false}
                                className='w-100 mb-3'
                                label="Select a City"
                                type="text"
                                value={selectedCity}
                                onChange={handleCityChange}
                            >
                                {filterCities.map((city) => (
                                    <MenuItem value={city}>
                                        {city.city ? city.name.toLocaleUpperCase() + " CITY" : city.name.toLocaleUpperCase()}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>   
                    }
                    </div>
{/* ========================================================================= DISABLED VALUE COUnTRY */}
                    <div className='col-lg-2'>
                        <TextField
                            disabled
                            id="outlined-disabled"
                            label="Country"
                            defaultValue="PHILIPPINES"
                            className='w-100 mb-3'
                        />
                    </div>
{/* ========================================================================= ENTER POSTAL CODE */}
                    <div className='col-lg-2'>
                        <TextField 
                        error={props.isErrorPageOne && props.postal_code === '' ? true : false}
                        helperText={props.isErrorPageOne && props.postal_code === '' ? 'Please fill out this field.' : ''}
                        className='mb-3 w-100'
                        label="Postal Code"
                        type="number"
                        name="postal_code"
                        value={props.postal_code}
                        onChange={props.handleInputChange}
                    />
                    </div>
                </div>
                <div className="row">
{/* ========================================================================= SELECT A PARKING TYPES */}
                    <div className='col-lg-6'>
                        <FormControl sx={{ minWidth: '100%' }}>
                        <InputLabel 
                            error={props.isErrorPageOne && props.parking_type === '' ? true : false}
                            id="demo-simple-select-helper-label"
                            >Select a Parking Type</InputLabel>
                        <Select
                            error={props.isErrorPageOne && props.parking_type === '' ? true : false}
                            className='w-100 mb-3'
                            label="Select a Parking Type"
                            type="text"
                            name="parking_type"
                            value={props.parking_type}
                            onChange={props.handleInputChange}
                        >
                
                        {props.parkingTypesList.map((types) => (
                            <MenuItem value={types.parking_type.toLocaleUpperCase()}>
                            {types.parking_type.toLocaleUpperCase()}
                            </MenuItem>
                        ))}
                        
                        </Select>
                        </FormControl>
                    </div>
{/* ========================================================================= SELECT A TIME ZONE */}
                    <div className='col-lg-6'>
                    <FormControl sx={{ minWidth: '100%' }}>
                    <InputLabel 
                        id="demo-simple-select-helper-label"
                        error={props.isErrorPageOne && props.time_zone === '' ? true : false}
                        >Select a Time Zone</InputLabel>
                    <Select
                        error={props.isErrorPageOne && props.time_zone === '' ? true : false}
                        className='w-100 mb-3'
                        label="Select a Time Zone"
                        type="text"
                        name="time_zone"
                        value={props.time_zone}
                        onChange={props.handleInputChange}
                    >
            
                    
                    <MenuItem value='ASIA/MANILA'>ASIA/MANILA</MenuItem>
                    </Select>
                    </FormControl>

                    </div>
{/* ========================================================================= ENTER DIRECTION */}
                <div className='col-lg-12'>
                    <FormHelperText className="mb-2">DIRECTION: Text based instruction(s) on how to easily find the charging station</FormHelperText>
                    <textarea 
                        placeholder="TURN LEFT on the parking entrance, TURN RIGHT after 10m"
                        className='w-100 p-3 mb-3 border border-secondary h-100'
                        label="Direction"
                        type="text"
                        name="direction"
                        value={props.direction}
                        onChange={props.handleInputChange}
                    /> 
                </div>
                </div>
            </form>
        </div>
    )
}
export default FormOne;