const RefreshToken = async (refreshToken) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + refreshToken);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };


    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_SERVICE_URL}/ocpi/cpo/api/auth/v1/refresh`,
            requestOptions
        );

        console.log("REFRESH TOKEN: ", refreshToken)
        let fetchData = response.json();
        console.log("RESPONSE: ", fetchData)
        if (!response.ok) {
            // Handle specific error status codes
            if (response.status === 403) {
                console.log("Unauthorized: Token invalid.");
                console.log(response)
                // localStorage.clear();
                // window.location.reload()
            } else {
                console.log(`Server error: ${response.status}`);
            }
        }
        return fetchData;
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

export default RefreshToken;