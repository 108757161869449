import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Logo from "./Components/Logo";
import TermsAndConditions from "./Components/TermsAndConditions";
import Website from "./Components/Website";

import GetCPODetails from "../../../Functions/GetCPODetails";

import { Box, Typography } from "@mui/material";

const Details = (props) => {
	const [CPODetails, setCPODetails] = useState({});
	const [CompanyLogo, setCompanyLogo] = useState("");
	const [website, setwebsite] = useState("");
	const [terms_and_conditions, setterms_and_conditions] = useState("");
	const navigate = useNavigate();
	const [cookies, setCookie] = useCookies();

	const isEmptyObject = (obj) => {
		return Object.keys(obj).length === 0 && obj.constructor === Object;
	};

	const GetDetails = () => {
		let request = GetCPODetails(cookies, setCookie);
		request.then((res) => {
			if (res.status === 200) {
				setCPODetails(res.data);
				props.onSetTokenC(res.data.token_c);
			}
			console.log(res);
		});
	};

	useEffect(() => {
		GetDetails();
	}, []);

	useEffect(() => {
		console.log(CPODetails);
		if (!isEmptyObject(CPODetails)) {
			setCompanyLogo(process.env.REACT_APP_FILE_PATH + CPODetails.logo);
		}
	}, [CPODetails]);

	const [dataCompany, setDataCompany] = useState([]);
	// It fetches CPO details from the API endpoint and updates state variables accordingly.)
	// const CPODetails = async () => {
	//     await fetch(process.env.REACT_APP_API_ADDRESS + "/get_company_utils", { //================= 9.1.1 Makes a GET request to the 'get_company_utils' endpoint to fetch company details.)
	//         method: 'GET',
	//         headers: {
	//             'pragma': 'no-cache',
	//             'cache-control': 'no-cache',
	//             'Content-Type': 'application/json',
	//             'Authorization': 'Bearer ' + cookies.auth_token // Includes the user's authorization token in the request header.)
	//         }
	//     })
	//         .then(response => response.json()) //================================================== 9.1.2 The response from the API is converted to JSON format.)
	//         // The result of the JSON conversion is then processed.)
	//         .then(result => {
	//             setDataCompany(result); //The state variable 'dataCompany' is updated with the entire result object.)
	//             // Specific data from the result is used to update other state variables.)
	//             setwebsite(result.website);
	//             setterms_and_conditions(result.tac)
	//         })
	//         .catch(error => console.log('error', error)); //======================================= 9.1.3 Any errors that occur during the fetch or processing are caught and logged to the console.)
	// };

	// UPDATE LOGO
	const [companyFiles, setcompanyFiles] = useState();
	const [modalIsOpenLogo, setIsOpenLogo] = useState(false);
	const [productLogo, setproductLogo] = useState(null);
	const [productSize, setproductSize] = useState();
	const [productFormat, setproductFormat] = useState("");
	const [productHeight, setproductHeight] = useState();
	const [productWidth, setproductWidth] = useState();
	const [LogoStatus, setLogoStatus] = useState();

	const handleImageLoad = (e) => {
		const img = e.target;
		setproductHeight(img.height);
		setproductWidth(img.width);
	};
	const HandleSubmitLogo = (e) => {
		const file = e.target.files[0];
		setproductLogo(URL.createObjectURL(e.target.files[0]));
		setcompanyFiles(e.target.files[0]);
		setproductSize(e.target.files[0].size);
		setproductFormat(e.target.files[0].type);
		if (file) {
			const reader = new FileReader();
			reader.onload = (event) => {
				const img = new Image();
				img.src = event.target.result;
				img.addEventListener("load", handleImageLoad);
			};
			reader.readAsDataURL(file);
		}
	};
	//============================================================================ 9.4 This function, SubmitLogo, is defined to handle the submission of a logo update)
	// const SubmitLogo = async (e) => {
	//     e.preventDefault(); // Prevents the default behavior of the event, typically a form submission.)
	//     const formData = new FormData(); //======================================= 9.4.1 Creates a new FormData object to handle file uploads.
	//     formData.append('logo', companyFiles); //================================= 9.4.2 Appends the selected companyFiles (logo) to the FormData object.
	//     await fetch(process.env.REACT_APP_API_ADDRESS + "/upload_logo", { //====== 9.4.3 Makes a PATCH request to the 'upload_logo' endpoint to submit a logo update.)
	//         method: 'PATCH',
	//         body: formData,  // The request body includes the FormData object containing the logo file.)
	//         headers: {
	//             'Authorization': 'Bearer ' + cookies.auth_token //=================== Includes the user's authorization token in the request header.)
	//         }
	//     })
	//         .then((response) => response.json()) //=================================== 9.4.4 The response from the API is converted to JSON format.)
	//         // The result of the JSON conversion is then processed.)
	//         .then((value) => {
	//             // If the logo update is successful (contains a message), perform the following actions:)
	//             if (value.message) {
	//                 // Display a success message using the Swal (SweetAlert) library.)
	//                 Swal.fire({
	//                     position: 'center',
	//                     icon: 'success',
	//                     title: value.message,
	//                     showConfirmButton: false,
	//                     timer: 1500
	//                 })
	//                 CPODetails(); //================================================== 9.4.5 Call the 'CPODetails' function to fetch and update company details.)
	//                 setIsOpenLogo(false);
	//                 setLogoStatus(value.status); // Set the state variable 'logoStatus' with the status from the API response.)
	//                 closeModalLogo();
	//                 navigate('/'); // Navigate to the '/' route.)
	//             }
	//         })
	//         .catch(err => { console.log(err) }); //==================================== 9.4.6 Any errors that occur during the fetch or processing are caught and logged to the console.)
	// }

	const openModalLogo = () => {
		setIsOpenLogo(true);
	};
	const closeModalLogo = () => {
		document.body.style.overflow = "unset";
		setIsOpenLogo(false);
	};

	return (
		<div className='container p-4 mb-5'>
			<header className='header_section'>
				<div className='container'>
					<h1>EMSP Details</h1>
				</div>
			</header>
			<div className='container'>
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					{/* <div className='col-sm-5 card p-4'>
						<Logo
							dataCompany={dataCompany}
							companyFiles={companyFiles}
							modalIsOpenLogo={modalIsOpenLogo}
							productLogo={productLogo}
							productSize={productSize}
							productFormat={productFormat}
							productHeight={productHeight}
							productWidth={productWidth}
							handleImageLoad={handleImageLoad}
							HandleSubmitLogo={HandleSubmitLogo}
							// SubmitLogo={SubmitLogo}
							openModalLogo={openModalLogo}
							closeModalLogo={closeModalLogo}
							LogoStatus={LogoStatus}
						/>
					</div> */}
					<Box sx={{ width: "70%", padding: 4, border: "solid .5px gray", borderRadius: "8px" }}>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Box sx={{ display: "flex", justifyContent: "space-between" }}>
								<Box sx={{ width: "75%" }}>
									<label htmlFor='basic-url'>Party ID </label>
									<input
										className='form-control mb-2'
										value={CPODetails.party_id}
										disabled
									/>

									<label htmlFor='basic-url'>eMSP Company/Brand Name </label>
									<input
										className='form-control mb-3'
										value={CPODetails.cpo_owner_name}
										disabled
									/>
								</Box>
								<Box sx={{ width: "20%", border: "solid .5px gray", height: "100%" }}>
									<Logo
										company_logo={CompanyLogo}
										companyFiles={companyFiles}
										modalIsOpenLogo={modalIsOpenLogo}
										productLogo={productLogo}
										productSize={productSize}
										productFormat={productFormat}
										productHeight={productHeight}
										productWidth={productWidth}
										handleImageLoad={handleImageLoad}
										HandleSubmitLogo={HandleSubmitLogo}
										// SubmitLogo={SubmitLogo}
										openModalLogo={openModalLogo}
										closeModalLogo={closeModalLogo}
										LogoStatus={LogoStatus}
										onRefreshDetail={GetDetails}
									/>
								</Box>
							</Box>

							<Typography
								sx={{ fontSize: "16px", fontWeight: "bold" }}
								variant='subtitle2'>
								Contact Details
							</Typography>
							<Box sx={{ px: 2 }}>
								<label htmlFor='basic-url'>Contact Name </label>
								<input
									className='form-control mb-2'
									value={CPODetails.contact_name}
									disabled
								/>

								<label htmlFor='basic-url'>Mobile Number </label>
								<input
									className='form-control mb-2'
									value={CPODetails.contact_number}
									disabled
								/>

								<label htmlFor='basic-url'>Email Address </label>
								<input
									className='form-control mb-2'
									value={CPODetails.contact_email}
									disabled
								/>
							</Box>
						</Box>
						{/* <div className="row">
                            <div className="col-sm">
                                <Website
                                    dataCompany={dataCompany}
                                    OnChangeWebsite={OnChangeWebsite}
                                    isEditWebsite={isEditWebsite} // UPDATE WEBSITE
                                    ChangeButtonWebsite={ChangeButtonWebsite} // BTN CHANGE WEBSITE LINK
                                    SubmitWebsite={SubmitWebsite} // BTN SUBMIT UPDATE WEBSITE LINK
                                    CancelButtonWebsite={CancelButtonWebsite} // BTN CANCEL
                                    website={website}
                                />
                            </div>
                            <div className="col-sm">
                                <TermsAndConditions
                                    dataCompany={dataCompany}
                                    OnChangeTermsAndCondition={OnChangeTermsAndCondition}
                                    isEditTermsAndConditions={isEditTermsAndConditions} // UPDATE TERMS AND CONDITIONS
                                    ChangeButtonTermsAndConditions={ChangeButtonTermsAndConditions} // BTN CHANGE TERMS AND CONDITIONS LINK
                                    SubmitTermsAndConditions={SubmitTermsAndConditions} // BTN SUBMIT UPDATE TERMS AND CONDITIONS LINK
                                    CancelButtonTermsAndConditions={CancelButtonTermsAndConditions} // BTN CANCEL
                                    terms_and_conditions={terms_and_conditions}
                                />
                            </div>
                        </div> */}
					</Box>
				</Box>
			</div>
		</div>
	);
};

export default Details;
