const Logout = async (access_token) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access_token);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
    };

    let fetchData;

    try {
        let response = await fetch(
            `${process.env.REACT_APP_SERVICE_URL}/ocpi/cpo/api/auth/v1/logout`,
            requestOptions
        );

        if (!response.ok) {
            // Handle specific error status codes
            if (response.status === 403) {
                console.log("Unauthorized: Token invalid.");
                console.log(response)
                // localStorage.clear();
                // window.location.reload()
            } else {
                console.log(`Server error: ${response.status}`);
            }
        }
        fetchData = response.json();

    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }

    return fetchData;

}

export default Logout;