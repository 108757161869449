import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { bgcolor, color, padding, styled, width } from "@mui/system";

import { useCookies } from "react-cookie";

import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Avatar from "@mui/material/Avatar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import GetEVSEList from "../../../Functions/GetEVSEList";
import { cardHeaderClasses } from "@mui/material";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	"& .MuiDataGrid-row": {
		"& .MuiDataGrid-cell": {
			whiteSpace: "normal",
			lineHeight: "1.5",
			display: "flex",
			alignItems: "center",
			position: "sticky",
		},
		"& .MuiDataGrid-cell:nth-of-type(2)": {
			left: 0,
			zIndex: 2,
			backgroundColor: "white",
			// backgroundColor: "#0C0354",
			padding: "0 8px 0 8px",
		},

		// "& .MuiDataGrid-cell:nth-of-type(2) span": {
		// 	color: "white",
		// },

		"& .MuiDataGrid-cell:nth-of-type(3)": {
			left: "3%",
			zIndex: 2,
			backgroundColor: "white",
			// backgroundColor: "#0C0354",
			// color: "white",
			padding: "0 8px 0 8px",
			boxShadow: "2px 0 5px -2px rgba(0,0,0,0.3)",
		},
		"& .MuiDataGrid-cell:nth-of-type(11)": {
			justifyContent: "center",
			right: 0,
			zIndex: 2,
			backgroundColor: "white",
			padding: "0 8px 0 8px",
			boxShadow: "-2px 0 5px -2px rgba(0, 0, 0, 0.3)",
		},
		"& .MuiDataGrid-cell:nth-of-type(4)": {
			justifyContent: "center",
		},
	},
	"& .MuiDataGrid-columnHeaders": {
		lineHeight: "1.5",
	},
	"& .MuiDataGrid-columnHeader:nth-of-type(2)": {
		position: "sticky",
		left: 0,
		zIndex: 2,
		backgroundColor: "white",
		// backgroundColor: "#0C0354",
	},
	// "& .MuiDataGrid-columnHeader:nth-of-type(2) span": {
	// 	color: "white",
	// },
	"& .MuiDataGrid-columnHeader:nth-of-type(3)": {
		position: "sticky",
		left: "3%",
		zIndex: 2,
		backgroundColor: "white",
		// backgroundColor: "#0C0354",
		// color: "white",
		borderRight: "1px solid rgba(224, 224, 224, 1)",
		boxShadow: "2px 0 5px -2px rgba(0,0,0,0.3)",
	},
	"& .MuiDataGrid-columnHeader:nth-of-type(11)": {
		position: "sticky",
		right: 0,
		zIndex: 2,
		backgroundColor: "white",
		borderLeft: "1px solid rgba(224, 224, 224, 1)",
		boxShadow: "-2px 0 5px -2px rgba(0, 0, 0, 0.3)",
	},
	"& .MuiDataGrid-columnHeader:nth-of-type(3).sticky": {
		borderRight: "1px solid rgba(224, 224, 224, 1)",
		boxShadow: "2px 0 5px -2px rgba(0,0,0,0.3)",
	},
}));

function createData(
	id,
	meterType,
	status,
	location_name,
	connectors,
	capabilities,
	payment_types,
	date_created,
	evse_id
) {
	let meter_type = meterType === null ? "N/A" : meterType;

	return { id, meter_type, status, location_name, connectors, capabilities, payment_types, date_created, evse_id };
}

function convertTimestampToDate(timestamp) {
	// Convert the timestamp to a date object
	const date = new Date(timestamp);

	// Format the date as a string (YYYY-MM-DD)
	const year = date.getUTCFullYear();
	const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
	const day = String(date.getUTCDate()).padStart(2, "0");

	return `${year}-${month}-${day}`;
}

const LocationCell = ({ params }) => {
	// useEffect(() => {
	// 	console.log(params);
	// }, []);

	const handleChange = (event) => {
		const { value } = event.target;
		params.api.setEditCellValue({ id: params.id, field: params.field, value });
	};

	return (
		<div>
			<Accordion sx={{ boxShadow: "none" }}>
				<AccordionSummary
					expandIcon={<ArrowDropDownIcon />}
					aria-controls='panel2-content'
					id='panel2-header'>
					<Typography>{params.value.name}</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
					<Typography variant='caption'>
						<Typography
							variant='caption'
							sx={{ fontWeight: "bold" }}>
							Address:
						</Typography>
						&nbsp;
						{params.value.address}
					</Typography>
					<Typography variant='caption'>
						<Typography
							variant='caption'
							sx={{ fontWeight: "bold" }}>
							Region:
						</Typography>
						&nbsp;
						{params.value.region}
					</Typography>
					<Typography variant='caption'>
						<Typography
							variant='caption'
							sx={{ fontWeight: "bold" }}>
							Postal Code:
						</Typography>
						&nbsp;
						{params.value.postal_code !== null ? params.value.postal_code : "N/A"}
					</Typography>
					<Button
						disabled
						sx={{ textTransform: "none", alignSelf: "flex-end" }}>
						See more details
					</Button>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

const ConnectorCell = ({ params }) => {
	const connectorCount = params.value.length;

	// useEffect(() => {
	// 	console.log(params);
	// }, []);

	const handleChange = (event) => {
		const { value } = event.target;
		params.api.setEditCellValue({ id: params.id, field: params.field, value });
	};

	return (
		<Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
			{params.value.map((connector, index) => {
				return (
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Button
							variant='text'
							startIcon={
								<Avatar sx={{ width: 24, height: 24, backgroundColor: "#121212" }}>
									{connector.connector_id === 1 ? "A" : "B"}
								</Avatar>
							}>
							{connector.standard.replaceAll("_", " ")}
						</Button>
						{connectorCount > 1 && index !== connectorCount - 1 && <Typography variant='h6'>&nbsp;/&nbsp;</Typography>}
					</Box>
				);
			})}
		</Box>
	);
};

const ListToolTipCell = ({ params }) => {
	// useEffect(() => {
	// 	console.log(params);
	// }, []);

	const handleChange = (event) => {
		const { value } = event.target;
		params.api.setEditCellValue({ id: params.id, field: params.field, value });
	};

	return (
		<Box sx={{ display: "flex", gap: 1, width: "100%" }}>
			{params.value.slice(0, 2).map((capability, index) => {
				return (
					<Chip
						sx={{
							borderRadius: params.field === "payment_types" ? "8px" : "unset",
							backgroundColor: params.field === "payment_types" && "gainsboro",
							fontWeight: params.field === "payment_types" && "bold",
						}}
						variant={params.field === "payment_types" ? "contained" : "outlined"}
						label={capability.description}
					/>
				);
			})}

			{params.value.length > 2 && (
				<Tooltip
					sx={{ backgroundColor: "red" }}
					componentsProps={{
						tooltip: {
							sx: {
								bgcolor: "common.white",
								"& .MuiTooltip-arrow": {
									color: "common.black",
								},
								boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)",
							},
						},
					}}
					arrow
					title={
						<Box
							sx={{
								display: "flex",
								gap: 1,
								backgroundColor: "white",
								maxWidth: "300px",
								flexWrap: "wrap",
								padding: "4px",
							}}>
							{params.value.map((capability, index) => {
								return (
									<Chip
										sx={{ borderRadius: "unset" }}
										variant='outlined'
										label={capability.description}
									/>
								);
							})}
						</Box>
					}
					placement='bottom'>
					<Chip
						sx={{ borderRadius: "unset", cursor: "pointer" }}
						variant='outlined'
						label={"+" + (params.value.length - 2)}
					/>
				</Tooltip>
			)}
		</Box>
	);
};

const ActionCell = ({ params }) => {
	return (
		<Box sx={{ display: "flex" }}>
			<ButtonGroup>
				<Tooltip title='Edit'>
					<Button
						variant='contained'
						size='small'
						color='success'>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Delete'>
					<Button
						variant='contained'
						size='small'
						color='error'>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</ButtonGroup>
		</Box>
	);
};

const columns = [
	{
		field: "id",
		headerName: "EVSE ID",
		width: 200,
		cardHeaderClasses: "header-sticky",
		renderCell: (params) => (
			<Box sx={{ position: "sticky", left: 0 }}>
				<Typography>{params.value}</Typography>
			</Box>
		),
	},
	{
		field: "meter_type",
		headerName: "Meter Type",
		width: 150,
		headerAlign: "center",
		// editable: true,
	},

	{
		field: "location_name",
		headerName: "Location",
		width: 250,
		// editable: true,
		sortable: false,
		headerAlign: "center",
		renderCell: (params) => <LocationCell params={params} />,
	},

	{
		field: "connectors",
		headerName: "Connectors",
		description: "This column has a value getter and is not sortable.",
		sortable: false,
		width: 150,
		headerAlign: "center",
		renderCell: (params) => <ConnectorCell params={params} />,
		// valueGetter: (value, row) => `${row.firstName || ""} ${row.lastName || ""}`,
	},
	{
		field: "status",
		headerName: "Status",
		width: 150,
		headerAlign: "center",
		renderCell: (params) => (
			<Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
				{" "}
				<Chip
					label={params.value}
					sx={{
						color: params.value === "UNASSIGNED" && "gray",
						borderColor: params.value === "UNASSIGNED" && "gray",
						fontWeight: "bold",
					}}
					color={
						params.value === "AVAILABLE" || params.value === "ACTIVE" || params.value === "ONLINE"
							? "success"
							: params.value === "OFFLINE" || params.value === "INACTIVE" || params.value === "DEFECTIVE"
							? "error"
							: params.value === "CHARGING"
							? "primary"
							: params.value === "RESERVED"
							? "warning"
							: "info"
					}
					variant='outlined'
				/>
			</Box>
		),
		// editable: true,
	},
	{
		field: "capabilities",
		headerName: "Capabilities",
		headerAlign: "center",
		width: 350,
		renderCell: (params) => <ListToolTipCell params={params} />,
	},
	{
		field: "payment_types",
		headerAlign: "center",
		headerName: "Payment Types",
		width: 200,
		renderCell: (params) => <ListToolTipCell params={params} />,
	},
	{
		field: "date_created",
		headerName: "Date Created",
		width: 150,
	},
	{
		field: "evse_id",
		headerName: "Action/s",
		headerAlign: "center",
		width: 200,
		renderCell: (params) => <ActionCell params={params} />,
	},
];

const EVSETable = () => {
	const [cookies, setCookie, removeCookie] = useCookies();
	const [EVSEList, setEVSEList] = useState([]);
	const [Limit, setLimit] = useState(10);
	const [OffSet, setOffSet] = useState(0);
	const [OrderBy, setOrderBy] = useState("location");
	const [Order, setOrder] = useState("DESC");

	const gridRef = useRef();

	useEffect(() => {
		// cookies, setCookie, limit, offset, orderBy, order;
		let request = GetEVSEList(cookies, setCookie, Limit, OffSet, OrderBy, Order);
		request.then((res) => {
			// id, status, location_name, connectors, capabilities, payment_types, facilities, parking_type;
			const rows = [];
			res.data.evses?.map((evse) => {
				const rowData = createData(
					evse.uid,
					evse.meter_type,
					evse.status,
					evse.locations,
					evse.connectors,
					evse.capabilities,
					evse.payment_types,
					convertTimestampToDate(Number(evse.date_created)),
					evse.evse_id
				);
				rows.push(rowData);
			});
			console.log(res.data.evses);
			setEVSEList(rows);
		});
	}, []);

	useEffect(() => {
		console.log("[EVSE LIST]: ", EVSEList);
	}, [EVSEList]);

	return (
		<Box sx={{ height: 600, width: "100%" }}>
			<StyledDataGrid
				rows={EVSEList}
				columns={columns}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 10,
						},
					},
					pinnedColumns: { left: ["id"] },
				}}
				getRowHeight={() => "auto"}
				pageSizeOptions={[10]}
				checkboxSelection
				disableRowSelectionOnClick
			/>
		</Box>
	);
};

export default EVSETable;
