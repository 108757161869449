import React, {useState, useEffect} from "react"
import FormOne from "./Components/formOne";
import FormTwo from "./Components/formTwo";
import FormThree from "./Components/formThree";
import { useNavigate  } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Swal from "sweetalert2";
const LocationRegister = (props) => {
const [cookies, setCookie, removeCookie] = useCookies('');
const navigate = useNavigate();
const parkingTypesList = props.parkingTypesList;
const chargingCapabilitiesList = props.chargingCapabilitiesList;
const facilitiesList = props.facilitiesList;
const parkingRestrictionsList = props.parkingRestrictionsList;
const connectorTypesList = props.connectorTypesList;
const connectorFormatList = props.connectorFormatList;
const powerTypesList = props.powerTypesList;
const  LocationSiteRegisterList = props.LocationSiteRegisterList
const initialvalues = {
    // PAGE 1
    location: '',
    city: '',
    province: '',
    country: 'PHILIPPINES',
    postal_code : '',
    parking_type : '',
    time_zone : '',
    direction : '',
  
    // PAGE 2
    floor_level : '',
    parking_restriction : '',
  
    // PAGE 3
    power_type : '',
    max_voltage : '',
    max_amperage : '',
    max_electric_power : '',
    price : '0',
  };
const [locationSiteInput, setLocationSiteInput] = useState(initialvalues)
const [facilities, setfacilities] = useState([]);
const [chargingCapabilities, setchargingCapabilities] = useState([]);
const [evseConnectors, setevseConnectors] = useState([
    {type: '', format: ''}
])
const [prices, setprices] = useState(
    {electricity_fee: null, service_fee: null, maintenance_fee: null, admin_fee: null, parking_fee: null, vat: null}
)

const handleInputChange = (e) => {
    setLocationSiteInput({ ...locationSiteInput, [e.target.name]: e.target.value });
};
const handleInputPrices = (e) => {
  setprices({ ...prices, [e.target.name]: e.target.value });
};


const [selectedRegions, setSelectedRegions] = useState([]);
const [selectedProvinces, setSelectedProvinces] = useState([]);
const [selectedCities, setSelectedCities] = useState([]);

const handleRegionChange = (event) => {
  // Update the selected region and reset province and city
  const region = event.target.value;
  setSelectedRegions(region);
  setSelectedProvinces([]);
  setSelectedCities([]);
};

const handleProvinceChange = (event) => {
  // Update the selected province and reset city
  const province = event.target.value;
  setSelectedProvinces(province);
  setSelectedCities([]);
};

const handleCityChange = (event) => {
  // Update the selected city
  const city = event.target.value;
  setSelectedCities(city);
};

// HANDLE CHARGING CAPABILITIES
const handleCheckChargingCapabilities = (event) => {
    var updatedList = [...chargingCapabilities];
        if (event.target.checked) {
        updatedList = [...chargingCapabilities, event.target.value];
        } else {
        updatedList.splice(chargingCapabilities.indexOf(event.target.value), 1);
        }
        setchargingCapabilities(updatedList);
};
  // RETURN CLASSES BASED ON WHETHER FACILITIES ITEM IS CHECKED
const isCheckedChargingCapabilities = (item) =>
    chargingCapabilities.includes(item) ? true : false;
  // HANDLE FACILITIES
const handleCheckFacilities = (event) => {
    var updatedList = [...facilities];
    if (event.target.checked) {
    updatedList = [...facilities, event.target.value];
    } else {
    updatedList.splice(facilities.indexOf(event.target.value), 1);
    }
    setfacilities(updatedList);
};
  // RETURN CLASSES BASED ON WHETHER FACILITIES ITEM IS CHECKED
const isCheckedFacilities = (item) =>
  facilities.includes(item) ? true : false;

//--------------------------------------------- EVSE CONNECTORS ------------------------------------------------------------------------------------//
const handleEvseConnectors = (e, index) => {
    const { name, value } = e.target;
    const list = [...evseConnectors];
    list[index][name] = value;
    setevseConnectors(list);
  };
  // HANDLE CLICK EVENT OF THE REMOVE BUTTON 
  const handleRemoveClick = index => {
    const list = [...evseConnectors];
    list.splice(index, 1);
    setevseConnectors(list);
  };
  // HANDLE CLICK EVENT OF THE ADD BUTTON
  const handleAddClick = () => {
    setevseConnectors([...evseConnectors, { type: "", format: "" }] );
  };
  

const Electricity = parseFloat(prices.electricity_fee) || 0;
const Service = parseFloat(prices.service_fee) || 0;
const Maintaince = parseFloat(prices.maintenance_fee) || 0;
const Admin = parseFloat(prices.admin_fee) || 0;
const Parking = parseFloat(prices.parking_fee) || 0;
const VAT = parseFloat(prices.vat) || 0;
const total_prices = Electricity + Service + Maintaince + Admin + Parking + VAT; 
// API POST: REGISTER LOCATION SITE
const isSuccess = true;
const timerDuration = 1500;
// Location Site Registration
//====================================================== 10.1 Function to handle New Location Site Registration form submission. 
const RegisterLocationSiteSubmit = async (e) => {
  e.preventDefault();
  Swal.fire({
    title: "Please confirm",
    text: "Are your sure you want to submit this form?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes"
  }).then(async (result) => {
    if (result.isConfirmed) {
//===================================================== 10.1.1 Makes a POST request to the 'register_location_site' endpoint to register a new location site.)
      await fetch(process.env.REACT_APP_API_ADDRESS + "/register_location_site", {
        method: 'POST', 
        body: JSON.stringify({ // The request body includes the data needed to register the location site in JSON format.)
          "status": "PENDING",
          "location": locationSiteInput.location,
          "city": selectedCities.name,
          "province": selectedProvinces.name,
          "country": locationSiteInput.country,
          "postal_code": locationSiteInput.postal_code,
          "parking_type": locationSiteInput.parking_type,
          "time_zone": locationSiteInput.time_zone,
          "direction": locationSiteInput.direction,
          "floor_level": locationSiteInput.floor_level,
          "parking_restriction": locationSiteInput.parking_restriction,
          "power_type": locationSiteInput.power_type,
          "max_voltage": locationSiteInput.max_voltage,
          "max_amperage": locationSiteInput.max_amperage,
          "max_electric_power": locationSiteInput.max_electric_power,
          "price": total_prices,
          "prices": prices,
          "facilities":facilities,
          "charging_capabilities": chargingCapabilities,
          "evse_connectors": evseConnectors
      }),
        headers: {
            'pragma': 'no-cache',
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + cookies.auth_token // Includes the user's authorization token in the request header.)
          }
      })
        .then(response => response.json()) //======================= 10.1.2 The response from the API is converted to JSON format.)
        //========================================================== 10.1.3 The result of the JSON conversion is then processed.)
        .then((value)=>{
           // If the location site registration is successful (status code 201), perform the following actions:)
          if(value.status === 201){
            Swal.fire({  // Display a success message using the Swal (SweetAlert) library.)
              position: "center",
              icon: "success",
              title: "Success",
              text: "The form has been submitted successfully",
              showConfirmButton: false,
              timer: 1500
            });
             // Call the 'LocationSiteRegisterList' function to update the list of registered location sites.)
            LocationSiteRegisterList();
            // If 'isSuccess' is true, navigate to the specified route after a timeout.)
            if (isSuccess) {
              setTimeout(() => {
                navigate(0);
              }, timerDuration);
            }
          }else{
             //  If the registration fails, display a warning message using the Swal library.)
            Swal.fire({
              position: "center",
              icon: "warning",
              title: "Submission failed!",
              showConfirmButton: false,
              timer: 1500
            });
             //  If 'isSuccess' is true, navigate to the specified route after a timeout.)
            if (isSuccess) {
              setTimeout(() => {
                navigate('/');
              }, timerDuration);
            }
          }
        })
        .catch(error => console.log('error', error)); //============  10.1.4 Any errors that occur during the fetch or processing are caught and logged to the console.)
    }
  });
  }
const [registerStatus, isRegisterStatus] = useState()

const [isErrorPageOne, setIsErrorPageOne] = useState(false)
const [isErrorPageTwo, setIsErrorPageTwo] = useState(false)
const [isErrorPageThree, setIsErrorPageThree] = useState(false)

const pageOne = () => {
  setIsErrorPageOne(true)
}
const pageTwo = () => {
  setIsErrorPageTwo(true)
}
const pageThree = () => {
  setIsErrorPageThree(true)
}
  return(
    <div className="hero_area">
      <div className="hero_bg_box">
          <div className="bg_img_box">
              <img src={props.BackgroundRegsitrationSite} alt="" />
          </div>
      </div>
      <div className="accordion p-4" id="accordionExample">
        <div className="accordion-item">
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <h1>EV CHARGER LOCATION SITE # {props.totalData + 1}</h1>
              <label>NOTE : Where should the charger be located</label>
                  <FormOne 
                    isErrorPageOne = {isErrorPageOne}
                    parkingTypesList = {parkingTypesList}
                    clickForHomePage = {props.clickForHomePage}
                    handleInputChange = {handleInputChange}
                    location = {locationSiteInput.location}
                    city = {locationSiteInput.city}
                    province = {locationSiteInput.province}
                    postal_code = {locationSiteInput.postal_code}
                    parking_type = {locationSiteInput.parking_type}
                    time_zone = {locationSiteInput.time_zone}
                    direction = {locationSiteInput.direction}

                    selectedRegions = {selectedRegions}
                    selectedProvinces = {selectedProvinces}
                    selectedCities = {selectedCities}
                    handleRegionChange = {handleRegionChange}
                    handleProvinceChange = {handleProvinceChange}
                    handleCityChange = {handleCityChange}
                  />
                  <br></br>
                <div className="d-flex justify-content-around">
                  <button className="btn btn-danger" onClick={props.toHome}>HOME</button>
                  {
                    (locationSiteInput.location === '' || selectedRegions.length === 0) ||
                    (selectedProvinces.length === 0 || selectedCities.length === 0) ||
                    (locationSiteInput.parking_type === '' || locationSiteInput.time_zone === '') 
                    // ||  locationSiteInput.direction === ''
                    ?   
                      <button 
                        onClick={pageOne}
                        className="btn btn-primary" 
                        type="button" 
                        > CONTINUE
                      </button>  
                    : 
                      <button 
                        className="btn btn-primary" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        data-bs-target="#collapseTwo" 
                        aria-expanded="false"
                        > CONTINUE
                      </button>
                  }
                </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <h1>EV CHARGER LOCATION SITE # {props.totalData + 1}</h1>
              <label>NOTE : Where should the charger be located</label>
                <FormTwo 
                  isErrorPageTwo = {isErrorPageTwo}
                  chargingCapabilitiesList = {chargingCapabilitiesList}
                  facilitiesList = {facilitiesList}
                  parkingRestrictionsList = {parkingRestrictionsList}
                  parking_restriction = {locationSiteInput.parking_restriction}
                  floor_level = {locationSiteInput.floor_level}
                  facilities = {facilities}
                  chargingCapabilities  = {chargingCapabilities}
                  handleInputChange = {handleInputChange}
                  handleCheckChargingCapabilities = {handleCheckChargingCapabilities}
                  isCheckedChargingCapabilities = {isCheckedChargingCapabilities}
                  handleCheckFacilities = {handleCheckFacilities}
                  isCheckedFacilities = {isCheckedFacilities}

                
                  />
              <div className="d-flex justify-content-around">
                <button 
                  className="btn btn-danger" 
                  type="button" 
                  data-bs-toggle="collapse" 
                  data-bs-target="#collapseOne" 
                  aria-expanded="true">
                    BACK
                </button>
                  {
                      (locationSiteInput.floor_level === '' || locationSiteInput.parking_restriction === '' ) ||
                      facilities.length === 0 || chargingCapabilities.length === 0
                    ?   
                      <button 
                        onClick={pageTwo}
                        className="btn btn-primary" 
                        type="button" 
                        > CONTINUE
                      </button>  
                    : 
                      <button 
                        className="btn btn-primary" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        data-bs-target="#collapseThree" 
                        aria-expanded="false"
                        > CONTINUE
                      </button>
                  }
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <h1>EV CHARGER LOCATION SITE # {props.totalData + 1}</h1>
              <label>NOTE : Where should the charger be located</label>
                <FormThree 
                  isErrorPageThree = {isErrorPageThree}
                  connectorTypesList = {connectorTypesList}
                  connectorFormatList = {connectorFormatList}
                  powerTypesList = {powerTypesList}
                  power_type = {locationSiteInput.power_type}
                  max_voltage = {locationSiteInput.max_voltage}
                  max_electric_power = {locationSiteInput.max_electric_power}
                  max_amperage = {locationSiteInput.max_amperage}
                  handleInputChange = {handleInputChange}
                  handleEvseConnectors = {handleEvseConnectors}
                  handleRemoveClick = {handleRemoveClick}
                  handleAddClick = {handleAddClick}
                  evseConnectors = {evseConnectors}
                  handleInputPrices = {handleInputPrices}
                  electricity_fee = {prices.electricity_fee}
                  service_fee = {prices.service_fee}
                  maintenance_fee = {prices.maintenance_fee}
                  admin_fee = {prices.admin_fee}
                  parking_fee = {prices.parking_fee}
                  vat = {prices.vat}
                  total_prices = {total_prices}
                  RegisterLocationSiteSubmit = {RegisterLocationSiteSubmit}
                  />
              <div className="d-flex justify-content-around">
                <button 
                  className="btn btn-danger" 
                  type="button" 
                  data-bs-toggle="collapse" 
                  data-bs-target="#collapseTwo" 
                  aria-expanded="false">
                    BACK
                </button>
                {
                  (locationSiteInput.power_type === '' || locationSiteInput.max_voltage === '' ) ||
                  (locationSiteInput.max_amperage === 0 || locationSiteInput.max_electric_power === 0) ||
                    prices.electricity_fee === null || prices.service_fee === null || prices.maintenance_fee === null || prices.admin_fee === null ||
                    prices.parking_fee === null || prices.vat === null
                ?   
                  <button 
                    onClick={pageThree}
                    className="btn btn-primary" 
                    type="button">
                      SUBMIT
                  </button>  
                : 
                  <button 
                    className="btn btn-primary" 
                    onClick={RegisterLocationSiteSubmit}>
                      SUBMIT
                  </button>
                  }
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default LocationRegister;